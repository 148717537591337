import React from 'react'
import { Provider } from 'react-redux'
import { Globalstyle } from './style.js'
import store from './store'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import 'moment/locale/zh-cn'

import Login from './page/login/index'
import StartUp from './page/startup/index'
//洗消管理 - 洗消点设置
import WashSet from './subPage/decontaminate/washSet/index'
//洗消管理 - 洗消流程设置
import WorkFlow from './subPage/decontaminate/workFlowPic/index'
//洗消管理 - 洗消中心管理
import WashGroup from './subPage/decontaminate/washGroup/index'
//车辆管理 - 车辆信息
import ResidentCarInfo from './subPage/car/residentCarInfo/index'
//车辆管理 - 临时车辆登记
import TempCarInfo from './subPage/car/tempCarInfo/index'
//车辆管理 - 车辆洗消记录
import DecontaminationRecord from './subPage/car/decontaminationRecord/index'
//物资管理 - 物资登记
import Register from './subPage/material/register/index'
//物资管理 - 物资洗消记录
import Decontamination from './subPage/material/decontamination/index'
//物资管理 - 物品库存
import Stock from './subPage/material/stock/index'
//物资管理 - 物品出入库
import OutAndWarehousing from './subPage/material/outAndWarehousing/index'
//人管管理 - 员工管理
import Staff from './subPage/personManage/staffTreeAll/index'
//人管管理 - 临时访客登记
import Visitor from './subPage/personManage/visitorTreeAll/index'
//人管管理 - 人员洗消记录
import PeopleWash from './subPage/personManage/peopleWash/index'


//设备管理-智能电表
import ElectricMeter from './subPage/device/electricMeter/index'

//设备管理-闸机
import Gate from './subPage/device/gate/index'

//设备管理-智能水表
import Water from './subPage/device/water/index'

//设备管理-视频监控设备
import VideoMonitor from './subPage/device/videoMonitor/index'
//系统用户
import SysUser from './subPage/system/user/index'


function App() {
  return (
    <React.Fragment>
      <Globalstyle/>
      <Provider store={store}>
        <ConfigProvider locale={zhCN}>
          <HashRouter>
            <Switch>
              <Route path='/' exact render={() => (<Redirect to="/login"></Redirect>)}></Route>
              <Route path='/login' component={Login}></Route>
              <Route path='/home' component={StartUp}></Route>
              <Route path='/decontaminate/WashSet' component={WashSet}></Route>
              <Route path='/decontaminate/WorkFlow' component={WorkFlow}></Route>
              <Route path='/decontaminate/WashGroup' component={WashGroup}></Route>
              <Route path='/car/ResidentCarInfo' component={ResidentCarInfo}></Route>
              <Route path='/car/TempCarInfo' component={TempCarInfo}></Route>
              <Route path='/car/DecontaminationRecord' component={DecontaminationRecord}></Route>
              <Route path='/material/Register' component={Register}></Route>
              <Route path='/material/Decontamination' component={Decontamination}></Route>
              <Route path='/material/Stock' component={Stock}></Route>
              <Route path='/material/OutAndWarehousing' component={OutAndWarehousing}></Route>
              <Route path='/personManage/Staff' component={Staff}></Route>
              <Route path='/personManage/Visitor' component={Visitor}></Route>
              <Route path='/personManage/PeopleWash' component={PeopleWash}></Route>
              <Route path='/equipment/VideoMonitor' component={VideoMonitor}></Route>
              <Route path='/equipment/Water' component={Water}></Route>
              <Route path='/equipment/ElectricMeter' component={ElectricMeter}></Route>
              <Route path='/equipment/Gate' component={Gate}></Route>
              <Route path='/user/SysUser' component={SysUser}></Route>
            </Switch>
          </HashRouter>
        </ConfigProvider>
      </Provider>
    </React.Fragment>
  )
}

export default App
