import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message } from 'antd'

import DeTable from '../../../component/DeTable'

// import moment from 'moment'

import StoreSettingHttp from '../../../request/demo'

const _request = new StoreSettingHttp()

function Demo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [name, setName] = useState('')
    const tableColunm = 
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '品类属性',
                dataIndex: 'nature',
                key: 'nature',
                render: (text, record, index) => (
                    <span>{record.state === 1 ? 'dddd' : record.state === 2 ? '3333' : '222'}</span>
                )
            },
            {
                title: '品类名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '创建日期',
                dataIndex: 'createDate',
                key: 'createDate',
            },
            {
                title: '创建人',
                key: 'recorder',
                dataIndex: 'recorder',
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openModal.bind(this, 'edit', record)}>编辑</Button>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <Button type="danger" size="small">删除</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
     //表格结构
    const [tableData, setTableData] = useState([]) //表格数据
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getAllType()
    }, [])
    //获取品类列表
    const getAllType = () => {
        let params = {
            name
        }
        _request.getAllType(params).then(res => {
            let list = []
            list = res.map(item => {
                return {
                    ...item,
                    key: item.id
                }
            })
            setTableData(list)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteType(params).then(res => {
            message.info('删除成功！')
            getAllType()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
            setTimeout(() => {
                setFieldsValue({
                    typePropE: record.nature,
                    typenameE: record.name,
                    id: record.id
                })
            }, 0)
        }
    }
    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
                if (modalType === 'add') {
                    let params = {
                        nature: getFieldValue('typeProp'),
                        name: getFieldValue('typename')
                    }
                    _request.addType(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getAllType()
                    })
                } else {
                    let params = {
                        nature: getFieldValue('typePropE'),
                        name: getFieldValue('typenameE'),
                        id: getFieldValue('id')
                    }
                    _request.editType(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getAllType()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        getAllType()
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10 }} onClick={openModal.bind(this, 'add')}>新增品类</Button>
                <Input placeholder="请输入品类名称" style={{ width: 200, marginRight: 10 }} value={name} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <Button type="primary" onClick={clickSearch}>搜索</Button>
            </div>
            <div className="bottom">
                <DeTable columns={tableColunm} pagination={{showSizeChanger: true, showTotal: (total) => `共 ${total} 条`}} dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增品类' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 100 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="品类属性：">
                                {getFieldDecorator('typeProp', {
                                    rules: [{ required: true, message: '请输入品类属性！' }],
                                })(
                                    <Input
                                        placeholder="请输入品类属性"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="品类名称：">
                                {getFieldDecorator('typename', {
                                    rules: [{ required: true, message: '请输入品类名称！' }],
                                })(
                                    <Input
                                        placeholder="请输入品类名称"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                    :
                    <Form style={{ height: 100 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="品类属性：">
                                {getFieldDecorator('typePropE', {
                                    rules: [{ required: true, message: '请输入品类属性！' }],
                                })(
                                    <Input
                                        placeholder="请输入品类属性"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="品类名称：">
                                {getFieldDecorator('typenameE', {
                                    rules: [{ required: true, message: '请输入品类名称！' }],
                                })(
                                    <Input
                                        placeholder="请输入品类名称"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(Demo)