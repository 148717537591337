import * as actionTypes from './actionTypes'
export const changeScreen = (data) => ({
    type: actionTypes.CHANGE_SCREEN,
    data
})

export const changeUserInfo = (data) => ({
    type: actionTypes.CHANGE_USERINFO,
    data
})

export const changePowerButtonList = (data) => ({
    type: actionTypes.CHANGE_POWERBUTTON_LIST,
    data
})

export const changeSpecialScreen = (data) => ({
    type: actionTypes.CHANGE_SPECIAL_SCREEN,
    data
})