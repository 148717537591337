import React from 'react'
import ReactDOM from 'react-dom'
import 'babel-polyfill'
import App from './App'
import './https'
import './css/common.css'
import './fonts/iconfont.css'
import './theme/css/theme.less'

ReactDOM.render(<App />, document.getElementById('root'))
