import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'

import { Container, Header, Aside, Content } from './style'

import { changeScreen, changePowerButtonList, changeSpecialScreen} from '../../store/actionCreators'

import moment from 'moment'

// 各页面组件

// 首页
import Home from '../../subPage/home/index'
import Home1 from '../../subPage/home1/index'

// 系统设置-组织机构
import Organization from '../../subPage/system/organize/index'
// 系统设置 - 用户管理
import User from '../../subPage/system/user/index'
// 系统设置 - 角色管理
import RoleManager from '../../subPage/system/role/index'
// 系统设置 - 系统字典
import Type from '../../subPage/system/type/index'
// 系统设置 - 访问失效时间设置
import ExpireTime from '../../subPage/system/expireTime/index'




//商城 - 品类管理
import Demo from '../../subPage/demo/typeManage/index'

//物资管理 - 物资登记
import Register from '../../subPage/material/register/index'
//物资管理 - 物资洗消记录
import Decontamination from '../../subPage/material/decontamination/index'
//物资管理 - 物品库存
import Stock from '../../subPage/material/stock/index'
//物资管理 - 物品出入库
import OutAndWarehousing from '../../subPage/material/outAndWarehousing/index'

//人管管理 - 员工管理
import Staff from '../../subPage/personManage/staffTreeAll/index'

//人管管理 - 临时访客登记
import Visitor from '../../subPage/personManage/visitorTreeAll/index'

//人管管理 - 人员洗消记录
import PeopleWash from '../../subPage/personManage/peopleWash/index'


//车辆管理
import ResidentCarInfo from '../../subPage/car/residentCarInfo/index'
import TempCarInfo from '../../subPage/car/tempCarInfo/index'
import DecontaminationRecord from '../../subPage/car/decontaminationRecord/index'

//设备管理-智能电表
import ElectricMeter from '../../subPage/device/electricMeter/index'

//设备管理-闸机
import Gate from '../../subPage/device/gate/index'

//设备管理-智能水表
import Water from '../../subPage/device/water/index'

//设备管理-视频监控设备
import VideoMonitor from '../../subPage/device/videoMonitor/index'

//洗消管理-洗消点设置
import WashSet from '../../subPage/decontaminate/washSet/index'
//洗消管理-洗消点流程设置
import WorkFlow from '../../subPage/decontaminate/workFlowPic/index'
//洗消中心
import WashGroup from '../../subPage/decontaminate/washGroup/index'

 
// 404
import Error from '../../subPage/error/index'

import Request from '../../request/other-request'

import { Icon, Menu, Tabs, Button, Tooltip } from 'antd'

const _request = new Request()

const { SubMenu } = Menu
const { TabPane } = Tabs

function StartUp(props) {
    const { subScreen, changeSubScreen, specialSubScreen, changePowerButtonList, changeSpecialScreen } = props
    const [currentKeys, setCurrentKeys] = useState(null) //左侧菜单当前选中项
    const [activeKey, setActiveKey] = useState('0') //tabs当前选中项
    const [menuList, setMenuList] = useState([]) //菜单列表
    const [user, setUser] = useState([]) //当前登录用户
    const [nowTime, setNowTime] = useState(moment().format('YYYY年MM月DD日 HH:mm:ss')) //当前时间
    useEffect(() => {
        _request.getMenu().then(res => {
            console.log(res)
            if (res.menuList && res.menuList.length !== 0) {
                setMenuList(res.menuList)
                let powerBtnList = []
                res.menuList.forEach(item => {
                    if (item.childMenu && item.childMenu.length !== 0) {
                        item.childMenu.forEach(sub => {
                            if (sub.childMenu && sub.childMenu.length !== 0) {
                                sub.childMenu.forEach(ssub => {
                                    if (ssub.permission && ssub.permission !== '') {
                                        powerBtnList.push({
                                            pageId: ssub.itemParentid,
                                            permission: ssub.permission
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
                changePowerButtonList(powerBtnList)
            }
        }).catch(err => {
            console.log(err)
        })
        //用户
        _request.getUser().then(res => {
        	setUser(res.user)
        }).catch(err => {
            console.log(err)
        })
        const timer = setInterval(() => {
            setNowTime(moment().format('YYYY年MM月DD日 HH:mm:ss'))
        }, 1000);
        return () => {
            clearInterval(timer)
        }
    },[])
    useEffect(() => {
        if (specialSubScreen.tabIndex && specialSubScreen.menuIndex){
            setActiveKey(specialSubScreen.tabIndex)
            setCurrentKeys(specialSubScreen.menuIndex)
        }
    }, [specialSubScreen])
    const clickMenu = (e) => {
        let screenList = [...subScreen]
        let state = false
        let id = e.key.split('&')[0]
        let title = e.key.split('&')[1]
        screenList.forEach((item, index) => {
            if (item.id === Number(id)) {
                state = true
                screenList[index].active = true
                setActiveKey(String(index))
            }else{
                screenList[index].active = false
            }
        })
        if (!state) {
            screenList.push({
                title,
                id: Number(id),
                active: true
            })
            setActiveKey(String(screenList.length - 1))
        }
        setCurrentKeys(e.key)
        changeSubScreen(screenList)
    }
    const closeScreen = (index, action) => {
        let screenList = [...subScreen] //注意需要拷贝一份再更新
        if (screenList[index].active) {
            screenList[index - 1].active = true
            setActiveKey(String(Number(index) - 1))
            setCurrentKeys(screenList[Number(index) - 1].id + '&' + screenList[Number(index) - 1].title)
        }else{
            if (Number(index) < Number(activeKey)) {
                setActiveKey(String(Number(activeKey) - 1))
            }
        }
        screenList.splice(index, 1)
        changeSubScreen(screenList)
    }
    const changeTab = (index) => {
        let screenList = [...subScreen]
        screenList[Number(activeKey)].active = false
        screenList[Number(index)].active = true
        setActiveKey(index)
        setCurrentKeys(screenList[Number(index)].id + '&' + screenList[Number(index)].title)
        changeSubScreen(screenList)
    }
    //退出登录
    const logoutNow = () => {
        changeSubScreen(
            [
                {
                    title: '首页',
                    id: -1,
                    active: true
                }
            ]
        )
        changeSpecialScreen({})
        props.history.push('/login')
    }
    //关闭所有tab
    const closeAllTab = () => {
        changeSubScreen(
            [
                {
                    title: '首页',
                    id: -1,
                    active: true
                }
            ]
        )
        setActiveKey('0')
        setCurrentKeys('-1&首页')
        changeSpecialScreen({})
    }
    //清空所有tabs的组件
    const clearAllTabs = <Button type="primary" shape="round" icon="close-circle" onClick={closeAllTab}>全部关闭</Button>
    return (
        <Container>
            <Header>
                <div className="home-icon-container">
                    <img className="home-icon" src={require('../../img/logo.png')} alt="主页logo" />
                </div>
                <div className="header-right">
                    <div className="header-title">
                        生物安全防控平台
                    </div>
                    <div className="header-welcome">
                        <span>{nowTime}</span>
                        <span>欢迎您！{user && user.account}</span>
                        <span onClick={logoutNow}>
                            <Tooltip arrowPointAtCenter mouseEnterDelay={0} title="！即将退出">
                                <Icon type="poweroff" /> 退出登录
                            </Tooltip>
                        </span>
                    </div>
                </div>
            </Header>
            <Aside>
                <Menu
                    mode="inline"
                    selectedKeys={[currentKeys]}
                    onClick={clickMenu}
                    style={{ width: '100%', borderRight: 'none' }}
                >
                    {
                        menuList.map((item, index) => {
                            return (
                                item.childMenu.length !== 0 ? 
                                <SubMenu
                                    key={item.itemId}
                                    title={
                                        <span>
                                            <span className={'iconfont ' + item.itemIcon} style={{marginRight: 8, width: 20, display: 'inline-block'}}></span>
                                            <span>{item.itemName}</span>
                                        </span>
                                    }
                                >
                                    {
                                        item.childMenu.map((subItem, subIndex) => {
                                            return (
                                                // subItem.childMenu.length === 0 ?
                                                <Menu.Item key={subItem.itemId + '&' + subItem.itemName}>{subItem.itemName}</Menu.Item>
                                            //     : 
                                            //     <SubMenu
                                            //         key={subItem.itemId}
                                            //         title={
                                            //             <span>
                                            //                 <span>{subItem.itemName}</span>
                                            //             </span>
                                            //         }
                                            //     >
                                            //         {
                                            //             subItem.childMenu.map((ssubItem, ssubIndex) => {
                                            //                 return (
                                            //                     <Menu.Item key={ssubItem.itemId + '&' + ssubItem.itemName}>{ssubItem.itemName}</Menu.Item>
                                            //                 )
                                            //             })
                                            //         }
                                            //    </SubMenu>
                                            )
                                        })
                                    }
                                </SubMenu>
                                 : 
                                <Menu.Item key={item.itemId + '&' + item.itemName}>
                                    <span className={'iconfont ' + item.itemIcon} style={{marginRight: 8, width: 20, display: 'inline-block'}}></span>
                                    <span>{item.itemName}</span>
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Aside>
            <Content>
                <div className="content-main">
                    <Tabs activeKey={activeKey} tabBarGutter={0} hideAdd type="editable-card" tabBarExtraContent={clearAllTabs} onEdit={closeScreen} onChange={changeTab}>
                        {
                            subScreen.map((item, index) => {
                                return (
                                    <TabPane tab={item.title} key={index} closable={index === 0 ? false : true}>
                                        <div className="content-inline">
                                            {
                                                item.id === -1 ? ((user && user.enterpriseType == 2 && user.regionType == 5) ? <Home1></Home1> : <Home></Home>) :
                                                item.id === 4 ? <Organization pageId={4}></Organization> :
                                                item.id === 2 ? <User pageId={2}></User> :
                                                item.id === 3 ? <RoleManager pageId={3}></RoleManager> :
                                                item.id === 5 ? <Type></Type> :
                                                item.id === 38 ? <ExpireTime></ExpireTime> :
                                                

                                                item.id === 15 ? <Demo></Demo> :
												
												item.id === 22 ? <Water></Water> :
												
												item.id === 23 ? <Gate></Gate> :
												
												item.id === 136 ? <Staff></Staff> :
												item.id === 137 ? <Visitor></Visitor> :
												item.id === 138 ? <PeopleWash></PeopleWash> :
												
                                                item.id === 140 ? <Register></Register> :
                                                item.id === 141 ? <Decontamination></Decontamination> :
                                                item.id === 142 ? <Stock></Stock> :
                                                item.id === 143 ? <OutAndWarehousing></OutAndWarehousing> :
                                                item.id === 145 ? <WashGroup></WashGroup> :
                                                item.id === 21 ? <VideoMonitor></VideoMonitor> :

                                                
                                                item.id === 105 ? <ResidentCarInfo></ResidentCarInfo> :
                                                item.id === 106 ? <TempCarInfo></TempCarInfo> :
                                                item.id === 107 ? <DecontaminationRecord></DecontaminationRecord> :
                                                item.id === 24 ? <ElectricMeter></ElectricMeter> :
                                          
                                                
                                                
                                                item.id === 31 ? <WashSet></WashSet> :
                                                item.id === 32 ? <WorkFlow></WorkFlow> :
                                                <Error></Error>
                                            }
                                        </div>
                                    </TabPane>
                                )
                            })
                        }
                    </Tabs>
                </div>
                <div className="footer">- 由深圳市润农科技有限公司提供技术支持 -</div>
            </Content>
        </Container>
    )
}

const mapState = (state) => ({
    subScreen: state.subScreen,
    specialSubScreen: state.specialSubScreen
})

const mapDispatch = (dispatch) => ({
    changeSubScreen(data){
        dispatch(changeScreen(data))
    },
    changePowerButtonList(data){
        dispatch(changePowerButtonList(data))
    },
    changeSpecialScreen(data) {
        dispatch(changeSpecialScreen(data))
    }
})

export default connect(mapState, mapDispatch)(StartUp)