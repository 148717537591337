import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import { Form, Button, Input, message, Divider, Table } from 'antd'

import ModalSource from './subpage/index'
import SystemRequest from '../../../request/system-request'
import {Container} from './style'
import DeTable from '../../../component/DeTable/index'

const sys_request = new SystemRequest();


const FormItem = Form.Item;
class SysTypeForm extends React.Component {
	constructor(props) {
		super(props);
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
		this.state = {
			table: {
				pageSize: 10,
				pageNo: 1,
				total: 0,
				dictName: "",//字典名称
				dictDesc: ""//字典小类名称
			},
			type: 'add',//默认是新增
			typelist: [],
			tcolumn: [],
			singdata: [],
			isShow: false//默认隐藏弹窗
		}
	}
	showTypeList() {
		debugger
		sys_request.sysTypeList({
			pageSize: this.state.pageSize,
			pageNo: this.state.pageNo,
			dictName: this.state.table.dictName,
			dictDesc: this.state.table.dictDesc
		}).then((res) => {
			this.setState({
				typelist: res.list,
				table: {
					total: res.total
				},
				tcolumn: [{
					title: '序号',
					render: (text, record, index) => (
						<span>{index + 1}</span>
					),
					dataIndex: 'number',
				}, {
					title: '大类名称',
					dataIndex: 'dictName'
				}, {
					title: '字典类型',
					dataIndex: 'dictType'
				},{
					title: '小类名称',
					dataIndex: 'dictDesc',
				}, {
					title: '字典值',
					dataIndex: 'dictValue',
				}, {
					title: '字典索引',
					dataIndex: 'dictIndex'
				}, {
					title: '操作',
					render: (text, record, index) => (<span><Button onClick={this.singleEdit.bind(this, record, index)} type="primary" size="small">编辑</Button>
						<Divider type="vertical" />
						<Button onClick={this.singleDel.bind(this, record, index)} size="small" type="danger">删除</Button></span>)
				}]
			})
		})
	}
	componentDidMount() {
		this.showTypeList();
	}
	//编辑字典
	singleEdit(record, index) {
		this.setState({
			isShow: true,
			type: 'edit',
			modaltitle: '编辑字典',
			singdata: record
		})

	}
	//新增字典
	addNewModal() {
		this.setState({
			isShow: true,
			type: 'add',
			modaltitle: '新增字典'
			//organizechoosedata:{userState:1,isOpenWeshow:1}
		})
	}
	//删除字典
	singleDel(record) {
		sys_request.sysDelType({ id: record.dictId }).then((res) => {
			let dataSource = [...this.state.typelist];
			dataSource = dataSource.filter(item => item.dictId !== record.dictId) 
			this.setState({ typelist: dataSource });
		})

	}
	handleSubmitSearch(e) {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (err) {
				return;
			}
			this.setState({
				table: {
					dictDesc: values.dictDesc,
					dictName: values.dictName
				}
			}, () => {
				this.showTypeList();
			})

		});
	}
	setValues(callback) {
		if (this.state.type === 'add') {

			sys_request.sysAddType({
				dictDesc: callback.dictDesc,
				dictIndex: callback.dictIndex,
				dictName: callback.dictName,
				dictType: callback.dictType,
				dictValue: callback.dictValue
			}).then((res) => {
				this.showTypeList();
				message.success('新增字典保存成功');
			})
		} else {
			sys_request.sysEditType({
				dictDesc: callback.dictDesc,
				dictIndex: callback.dictIndex,
				dictName: callback.dictName,
				dictType: callback.dictType,
				dictValue: callback.dictValue,
				dictId: callback.dictId
			}).then((res) => {
				this.showTypeList();
				message.success('编辑字典保存成功');
			})
		}
	}
	setIsShow(callback) {
		this.setState({
			isShow: callback
		})
	}
	

	//清空条件
	clearCondition() {
		this.props.form.setFieldsValue({
			dictDesc: '',
			dictName: ''
		});
		this.setState({
			table: {
				dictDesc: '',
				dictName: ''
			}
		}, () => {
			this.showTypeList()
		});
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<Container>
				<div style={{ margin: '0 0 10px 0' }}>
					<Form layout="inline" onSubmit={this.handleSubmitSearch.bind(this)}>
						<Button type="primary" className="searchconditionbtn" onClick={this.addNewModal.bind(this)}>新增</Button>
						
						<FormItem>
							{getFieldDecorator('dictName', {
								initialValue: this.state.table.dictName,
							})(
								<Input placeholder="请输入大类名称" />
							)}
						</FormItem>
						<FormItem>
							{getFieldDecorator('dictDesc', {
								initialValue: this.state.table.dictDesc,
							})(
								<Input placeholder="请输入小类名称"/>
							)}
						</FormItem>
						<FormItem>
							<Button
								type="primary"
								htmlType="submit"
							>
								查询
	          </Button>
						</FormItem>
						<FormItem>
							<Button
								type="primary"
								onClick={this.clearCondition.bind(this)}
							>
								清空
	          </Button>
						</FormItem>
					</Form>
				</div>
				<div className="bottom">
					<DeTable dataSource={this.state.typelist} columns={this.state.tcolumn} pagination={{ showSizeChanger: true, showTotal: (total) => `共 ${total} 条` }}></DeTable>
				</div>
				<ModalSource
					type={this.state.type}
					singdata={this.state.singdata}
					isShow={this.state.isShow}
					setValues={this.setValues.bind(this)}
					setIsShow={this.setIsShow.bind(this)}
					modaltitle={this.state.modaltitle}>
				</ModalSource>
			</Container>
		)
	}
}

const SysType = Form.create()(SysTypeForm);
export default SysType;