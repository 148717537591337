import request from '../https'

class HomeRequest {
    //获取web端首页查询近7个月水电能耗图表
    getEcharts1Data(params) {
        return request({
            url: '/client/homePage/getElectricMeterData',
            method: 'post',
            params
        })
    }
    //获取人、车洗消统计
    getEcharts2Data(params) {
        return request({
            url: '/client/completeEchars/getData',
            method: 'post',
            params
        })
    }
    //查询人车物近7个月的异常数据
    getEcharts3Data(params) {
        return request({
            url: '/client/homePage/getExeData',
            method: 'post',
            params
        })
    }
    //查询车物近7个月检疫合格数据图表
    getEcharts4Data(params) {
        return request({
            url: '/client/homePage/getVirusCheckData',
            method: 'post',
            params
        })
    }

    //今日洗消的数量
    getTodayWashData(params){
        return request({
            url: '/client/homePage/getTodayWashCount',
            method: 'post',
            params
        })
    }

    //实时概况
    getTimeSumData(params){
        return request({
            url: '/client/homePage/getTodayRtaInfo',
            method: 'post',
            params
        })
    }
}

export default HomeRequest