import request from '../https'

class SystemRequest {
 
    getLogin(ids) {
        return request({
            url: 'client/sys/login', 
            method: 'POST',
            data: ids
        })
    }
    //新增机构
    addNewOrginize(data) {
        return request({
            url: 'client/area/add',
            contentType: "application/json",
            method: 'POST',
            data: data
        })
    }

    //修改组织机构
    updateOrganize(info) {
        return request({
            url: 'client/area/update',
            contentType: "application/json",
            method: 'PUT',
            data: info
        });
    }

    //组织机构
    organizeList(pid) {
        return request({
            url: 'client/area/leftList',
            method: 'POST',
            data: pid
        });
    }
    //组织机构子级列表
    organizeChildList(info) {
        return request({
            url: 'client/area/list',
            method: 'POST',
            data: info
        });
    }
    // 组织机构详情
    organizeDetail(info) {
        return request({
            url: 'client/area/detail/' + info,
            method: 'GET'
        });
    }
    //删除组织机构
    deleteOrganize(info) {
        return request({
            url: 'client/area/delete/' + info,
            method: 'DELETE'
        });
    }

    //角色列表
    roleList(data) {
        return request({
            url: 'client/role/list',
            method: 'POST',
            data: data
        });
    }
    //新增保存角色
    addRole(data) {
        return request({
            url: 'client/role/add',
            method: 'POST',
            data: data
        });
    }
    //修改保存角色
    updateRole(data) {
        return request({
            url: 'client/role/update',
            method: 'PUT',
            data: data
        });
    }
    //删除角色
    deletePower(id) {
        return request({
            url: 'client/role/delete/' + id.id,
            method: 'DELETE'
        });
    }
    //加载菜单
    roleMenuList(id) {
        return request({
            url: 'client/role/menu',
            method: 'POST',
            data: id
        });
    }
    //保存权限
    savePower(ids) {
        return request({
            url: 'client/role/grantmenu',
            method: 'PUT',
            data: ids
        });
    }
    //用户列表
    userList(ids) {
        return request({
            url: 'client/user/list',
            method: 'POST',
            data: ids
        });
    }

    userListType(ids) {
        return request({
            url: 'client/user/listType',
            method: 'POST',
            data: ids
        });
    }
    //新增保存用户
    addSaveUser(ids) {
        return request({
            url: 'client/user/add',
            method: 'POST',
            contentType: "application/json",
            data: ids
        });
    }

    //编辑保存用户
    editSaveUser(ids) {
        return request({
            url: 'client/user/update',
            method: 'PUT',
            contentType: "application/json",
            data: ids
        });
    }
    //获取用户详情
    sysUserDetail(obj) {
        return request({
            url: 'client/user/userDetail/' + obj.id,
            method: 'GET'
        });
    }
    //获取新增用户的角色列表

    sysUserRoleList() {
        return request({
            url: 'client/user/roleList',
            method: 'POST'
        });
    }
    //删除用户
    deleteSysUser(obj) {
        return request({
            url: 'client/user/delete/' + obj.id,
            method: 'DELETE'
        });
    }
    //系统字典列表
    sysTypeList(ids) {
        return request({
            url: 'client/dict/list',
            method: 'POST',
            data: ids
        });
    }
    //新增系统字典保存
    sysAddType(ids) {
        return request({
            url: 'client/dict/add',
            method: 'POST',
            data: ids
        });
    }
    //编辑系统字典保存
    sysEditType(ids) {
        return request({
            url: 'client/dict/update',
            method: 'PUT',
            data: ids
        });
    }
    //删除系统字典
    sysDelType(ids) {
        return request({
            url: 'client/dict/delete/' + ids.id,
            method: 'DELETE'
        });
    }
    //获取对应字典
    getSysType(ids) {
        return request({
            url: 'client/dict/cacheDictList',
            method: 'POST',
            data: ids
        });
    }
    //获取省市接口
    getProvice() {
        return request({
            url: 'city.json',
            method: 'GET'
        });
    }

    //公众号用户列表
    getWxUser(ids) {
        return request({
            url: 'slaughter/wx/getWxUser',
            method: 'POST',
            data: ids
        });
    }
    //获取对应字典
    addWxUser(ids) {
        return request({
            url: 'slaughter/wx/addWxUser',
            method: 'POST',
            data: ids
        });
    }
}

export default SystemRequest