import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'
import { DatePicker } from 'antd';

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import DecontaminationHttp from '../../../request/car-request'
import CommonHttp from '../../../request/common-request'

const _request = new DecontaminationHttp()
const _commonrequest = new CommonHttp()

const Option = Select.Option;

const { MonthPicker, RangePicker } = DatePicker;

function DecontaminationRecord(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [carNo, setCarNo] = useState('')
    const [supervisor, setSupervisor] = useState('')
    const [type, setType] = useState('true')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    
    const tableColunm = 
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '车牌号',
                dataIndex: 'carNo',
                key: 'carNo',
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '监管人',
                dataIndex: 'supervisor',
                key: 'supervisor',
            },
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
            {
                title: '流程',
                key: 'workflowName',
                dataIndex: 'workflowName',
            },
           {
                title: '视频位置',
                key: 'videoName',
                dataIndex: 'videoName',
            },
            {
                title: '操作时间',
                dataIndex: 'operationDate',
                key: 'operationDate',
				render: (text, record, index) => {
					return <span>{moment(record.operationDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '类型',
                key: 'type',
                dataIndex: 'type',
                render: (text, record, index) => (
                    <span>{record.type === '1' ? '常驻车辆' :  '临时车辆'}</span>
                )
            },
            {
                title: '车辆类型',
                key: 'carType',
                dataIndex: 'carType',
                render: (text, record, index) => (
                	carTypeData.map((item,index1) => {
						if(record.carType === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})
                   
                )
            },
            {
                title: '车辆图片',
                key: 'imgPath',
                dataIndex: 'imgPath',
                render: (imgPath,record) => (
                    <Button type="primary" size="small" onClick={imagesPath.bind(this, imgPath, record)}>查看图片</Button>
                )
            }
        ]

    
    //查看图片
	const [imagePath, setImagePath] = useState(false) //图片查看
	const [imgVehicle, setImgVehicle] = useState('')
	const [imagPath, setImagPath] = useState('')
    const [extractWork, setExtractWork] = useState('')
    const qrStyle ={
		color:'white',
		margin: '10px auto',
		width: '70%'
	}
	const centerStyle={
		width: '100%',
		textAlign: 'center'
	}
	const imageEdit = () =>{
        setImagePath(false)
    }
	const imagesPath = (imgPath,record) => {
		setImgVehicle(record)
		setImagePath(true)
		setImagPath(imgPath)
		const extractWork = record.workflowName
		setExtractWork(extractWork.substring(0,4))
	}
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [carTypeData, setCarTypeData] = useState([]) //车辆类型列表
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getDecontaminationRecordList()
        getDictList()
    }, [])
    //获取列表
    const getDecontaminationRecordList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            carNo:carNo,
            supervisor:supervisor,
            startTime:startTime,
            endTime: endTime,
            type:'web',//web端查询默认查询历史纪录
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {
            	type:'true',//web端查询默认查询历史纪录
	            startTime:'',
	            endTime: ''
            }
        }
        _request.getDecontaminationRecordList(params).then(res => {
        	setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
   
     //获取车辆类型列表
    const getDictList = () => {
        let params = {
           dictType:'CAR_TYPE'
        } 
        _commonrequest.getDictList(params).then(res => {
            setCarTypeData(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        getDecontaminationRecordList()
    }
    //重置
    const clickReset = () => {
		setSupervisor('')
		setCarNo('')
		setStartTime('')
		setEndTime('')
        getDecontaminationRecordList('reset')
    }
    
   
    
    //日期控件搜索
    function range(start, end) {
	  const result = [];
	  for (let i = start; i < end; i++) {
	    result.push(i);
	  }
	  return result;
	}
	
	function disabledDate(current) {
	  // Can not select days before today and today
	  return current && current < moment().endOf('day');
	}

    function disabledDateTime() {
	  return {
	    disabledHours: () => range(0, 24).splice(4, 20),
	    disabledMinutes: () => range(30, 60),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
	function disabledRangeTime(_, type) {
	  if (type === 'start') {
	    return {
	      disabledHours: () => range(0, 60).splice(4, 20),
	      disabledMinutes: () => range(30, 60),
	      disabledSeconds: () => [55, 56],
	    };
	  }
	  return {
	    disabledHours: () => range(0, 60).splice(20, 4),
	    disabledMinutes: () => range(0, 31),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
    function rangeTimeChange(date){
    	if(date){
    		if(date[0]!=undefined){
    			setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setStartTime('');
    		}
    		if(date[1]!=undefined){
    			setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setEndTime('');
    		}
    	}
    }
    
     	
    return (
        <Container>
            <div className="top">
                <Input placeholder="请输入车牌号" style={{ width: 200, marginRight: 10 }} value={carNo} onPressEnter={clickSearch} onChange={(e) => setCarNo(e.target.value)}></Input>
                <Input placeholder="请输入监管人" style={{ width: 200, marginRight: 10 }} value={supervisor} onPressEnter={clickSearch} onChange={(e) => setSupervisor(e.target.value)}></Input>
                <RangePicker
                	ranges={{
				        Today: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				        'This Month': [moment().startOf('month'), moment().endOf('month')],
				      }}
	                value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
				   /* disabledTime={disabledRangeTime}*/
				    onChange={rangeTimeChange}
				    showTime={{
				    	hideDisabledOptions: true,
				        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				    }}
				    format="YYYY-MM-DD HH:mm:ss"
				/>
	                
               	&nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickReset}>重置</Button>
            </div>
            <div className="bottom"> 
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getDecontaminationRecordList('', pageNo, pageSize)}  dataSource={tableData} />
            </div>

            <Modal
                title={'人脸图片'}
                visible={imagePath}
                centered={true}
                width={'50%'}
                onCancel={imageEdit}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                
                {
                    imagPath ?
					<div style={qrStyle}>
						<div style={centerStyle}><img width='100%' src={imagPath} alt="" /></div>
						<div style={{ marginTop: 10 }}>
							<p>车牌号：{ imgVehicle.carNo ? imgVehicle.carNo : '无' }</p>
							<p>洗消点：{ imgVehicle.washSpotName ? imgVehicle.washSpotName : '无' }</p>
							<p>监管人：{ imgVehicle.supervisor ? imgVehicle.supervisor : '无' }</p>
							<p>位置：{ imgVehicle.videoName ? (imgVehicle.workflowId == -1 ? extractWork + ' ' + imgVehicle.videoName : imgVehicle.videoName) : imgVehicle.workflowName }</p>
							<p>操作时间：{ imgVehicle.operationDate ? moment(imgVehicle.operationDate).format("YYYY-MM-DD HH:mm:ss") : '无' }</p>
							<p>类型：{ imgVehicle.type ? imgVehicle.type : '无' }</p>
							<p>车辆类型：{ imgVehicle.carType ? imgVehicle.carType : '无' }</p>
						</div>
					</div>
					:
                    <div style={ qrStyle }>暂无图片</div>
				}
            </Modal>
        </Container>

    )
}

export default Form.create()(DecontaminationRecord)