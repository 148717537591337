import axios from 'axios'
import qs from 'qs'
import {message} from 'antd'
// hmy：http://172.16.4.37:8089
// http://test.rnpig.com/
axios.defaults.baseURL = ''
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
    // 判断请求的类型
    // 如果是post请求就把默认参数拼到data里面
    // 如果是get请求就拼到params里面
    if ((config.method === 'post' || config.method === 'put') && config.headers["Content-Type"] === 'application/x-www-form-urlencoded') {
        let data = qs.parse(config.data)
        config.params = {
            ...config.params
        }
        config.data = qs.stringify({
            ...data
        }, {
            arrayFormat: 'brackets'
        })
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
    //对响应数据做些事
    // if (res.config.method === 'get') {
    //     return res
    // }
    if (res.data.code === '0000') {
        // if (res.config.method === 'post') {
            return res.data.data
        // }
    } else if (res.data.code === "304") {
        message.warn('2秒后将重新登录……')
        setTimeout(() => {
            window.location.href = '/#/login'
        }, 2000)
    } else {
        return Promise.reject(res.data.message)
    }
}, (error) => {
    return Promise.reject('请求异常')
})

function request(params){
    return new Promise((resolve, reject) => {
        axios({
            url: params.url || '',
            method: params.method || 'post',
            params: params.params || {},
            data: params.data || {},
            headers: {
                'Content-Type': params.contentType || 'application/x-www-form-urlencoded',
                sessionId: getSessionId()
            },
            paramsSerializer: function (param) {
                return qs.stringify(param, {
                    arrayFormat: 'repeat'
                })
            },
        }).then(res => {
            resolve(res)
        }).catch(err => {
            console.log(err)
            message.error(err || '出问题')
            reject(err)
        })
    })
}
function getSessionId(){
    let sessionId = '', paramsStr = window.location.href.split('?')[1];
    if(paramsStr){
      let paramsObj = {};
      paramsStr.replace(/&amp;/g, '&').split('&').forEach(item => {
        let param = item.split('=');
        paramsObj[param[0]] = param[1];
      })
      sessionId = paramsObj.sessionId;
    }
    return sessionId;
}
export default request
