import React, { useEffect, useState } from 'react'

import { Container } from './style'

import echarts from '../../echarts_init'

import HomeRequest from '../../request/home'

import {Icon} from 'antd'

const _request = new HomeRequest()

function ErrorPage() {
    useEffect(() => {
        getEcharts1Data()
        getEcharts3Data()
        getTodayWashData()
        getTimeSumData()
    }, [])
    const [todayWashObj, setTodayWashObj] = useState({})
    const [todaySumList, setTodaySumList] = useState([])
    const getEcharts1Data = () => {
        _request.getEcharts1Data().then(res => {
            let date = []
            let waterData = []
            let electricData = []
            res.meterEchartsArr.forEach(item => {
                date.push(item.date)
                waterData.push(item.value)
            })
            res.electricEchartsArr.forEach(item => {
                electricData.push(item.value)
            })
            initEcharts1(date, waterData, electricData)
        })
    }
    const getEcharts3Data = () => {
        _request.getEcharts3Data().then(res => {
            let date = []
            let carData = []
            let peopleData = []
            let objectData = []
            res.carEchartsArr.forEach(item => {
                date.push(item.date)
                carData.push(item.count)
            })
            res.peopleEchartsArr.forEach(item => {
                peopleData.push(item.count)
            })
            res.goodEchartsArr.forEach(item => {
                objectData.push(item.count)
            })
            initEcharts3(date, carData, peopleData, objectData)
        })
    }

    const getTodayWashData = () => {
        _request.getTodayWashData().then(res => {
            setTodayWashObj(res)
        })
    }

    const getTimeSumData = () => {
        _request.getTimeSumData().then(res => {
            setTodaySumList(res)
        })
    }

    const initEcharts1 = (date, waterData, electricData) => {
        echarts.init(document.getElementById('charts-1'), 'co-op-theme').setOption({
            legend: {
                data: ['水表', '电表']
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params[0].name + '月</br>' + params[0].seriesName + '：' + (params[0].data === 'null' ? '-' : (params[0].data + '吨')) + '</br>' + params[1].seriesName + '：' + (params[1].data === 'null' ? '-' : (params[1].data + '度'))
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '80%',
                width: '80%',
                y2: 0,
                x: '10%'
            },
            yAxis: [
                {
                    type: 'value',
                    name: '水表（吨）'
                },
                {
                    type: 'value',
                    name: '电表（度）'
                },
            ],
            xAxis: {
                type: 'category',
                data: date,
                name: '月份',
                axisLabel: {
                    interval: 0
                },
            },
            series: [
                {
                    name: '水表',
                    type: 'line',
                    yAxisIndex: 0,
                    label: {
                        normal: {
                            show: true,
                            position: 'bottom'
                        }
                    },
                    data: waterData
                },
                {
                    name: '电表',
                    type: 'line',
                    yAxisIndex: 1,
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: electricData
                },
            ]
        })
    }
    const initEcharts3 = (date, carData, peopleData, objectData) => {
        echarts.init(document.getElementById('charts-3'), 'co-op-theme').setOption({
            legend: {
                data: ['车辆', '人员', '物资']
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params[0].name + '月</br>' + params[0].seriesName + '：' + (params[0].data === 'null' ? '-' : params[0].data) + '</br>' + params[1].seriesName + '：' + (params[1].data === 'null' ? '-' : params[1].data)
                        + '</br>' + params[2].seriesName + '：' + (params[2].data === 'null' ? '-' : params[2].data)
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '80%',
                width: '80%',
                y2: 0,
                x: '10%'
            },
            yAxis: {
                type: 'value',
                name: '数量'
            },
            xAxis: {
                type: 'category',
                data: date,
                name: '月份',
                axisLabel: {
                    interval: 0
                },
            },
            series: [
                {
                    name: '车辆',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: carData
                },
                {
                    name: '人员',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: peopleData
                },
                {
                    name: '物资',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: objectData
                }
            ]
        })
    }
    return (
        <Container>
            <div className="top">
                <div className="custom-card chartsItem todyWash">
                    {/* <div className="custom-card-title">今日洗消</div> */}
                    <div className="custom-card-content">
                        <div className="todayContent">
                            <div className="todayItem">
                                <img src={require("../../img/car.png")} alt="" />
                                <p>
                                    <span>车辆</span>
                                    <span>{todayWashObj.carCount || 0}</span>
                                </p>
                            </div>
                            <div className="todayItem">
                                <img src={require("../../img/people.png")} alt="" />
                                <p>
                                    <span>人员</span>
                                    <span>{todayWashObj.peopleCount || 0}</span>
                                </p>
                            </div>
                            <div className="todayItem">
                                <img src={require("../../img/object.png")} alt="" />
                                <p>
                                    <span>物资</span>
                                    <span>{todayWashObj.goodCount || 0}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="custom-card chartsItem timeSum">
                    {/* <div className="custom-card-title">实时概况</div> */}
                    <div className="custom-card-content">
                        <div className="sumContainer">
                            <div className="sumContent">
                                {
                                    todaySumList.length === 0 ? <div className="pContainer"><p>暂无【实时概况】</p></div> :
                                    todaySumList.map((item, index) => {
                                        return <div key={index} className="pContainer"><Icon style={{marginBottom: 8, marginRight: 6, color: '#5A8BFF'}} type="clock-circle"></Icon><p>{item}</p></div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="custom-card chartsItem">
                    <div className="custom-card-title">水电耗能</div>
                    <div className="custom-card-content">
                        <div className="chartsContent">
                            <span className="chartsData" id="charts-1"></span>
                        </div>
                    </div>
                </div>

                <div className="custom-card chartsItem">
                    <div className="custom-card-title">异常数据</div>
                    <div className="custom-card-content">
                        <div className="chartsContent">
                            <span className="chartsData" id="charts-3"></span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ErrorPage