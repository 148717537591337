import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .chartsItem{
        margin: 8px;
        width: calc(50% - 16px);
        height: calc(50% - 16px);
        .custom-card-content{
            display: flex;
            justify-content: space-between;
            height: calc(100% - 60px);
            .chartsContent{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                .chartsData{
                    font-size: 32px;
                    color: #333333;
                    width: 100%;
                    height: 100%;
                    padding: 8px;
                }
            }
        }
    }
`