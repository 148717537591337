import request from '../https'

class ExpireTimeRequest{
	//访问失效时间设置列表=========================
    getExpireTimeList(params){
        return request({
            url: 'client/expireTime/list',
            method: 'post',
            params
        })
    }
    
   
    //新增访问失效时间设置
    expireTimeAdd(params) {
        return request({
            url: 'client/expireTime/add',
            method: 'POST',
            params
        });
    }
    //修改访问失效时间设置
    expireTimeUpdate(params) {
        return request({
            url: 'client/expireTime/update',
            method: 'PUT',
            params
        });
    }
    //删除访问失效时间设置
    expireTimeDelete(params) {
        return request({
            url: 'client/expireTime/delete/' + params.id,
            method: 'DELETE'
        });
    }
    
   
    
}

export default ExpireTimeRequest