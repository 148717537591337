import styled from 'styled-components'

export const Container = styled.div `
    width: fit-content;
    display: inline-block;
    .title{
        padding: 10px 0;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }
    .stepIcon{
        background: #5A8BFF;
        border-radius: 50%;
        font-size: 16px;
        color: white;
        transition: all .3s ease-in;
        &:hover{
            transition: all .3s ease-in;
            cursor: pointer;
            background: #ff5c57;
            span{
                display: none;
            }
            .close{
                display: inline;
            }
        }
        span{
            display: inline;
        }
        .close{
            display: none;
        }
    }
    .addFlow{
        cursor: pointer;
        transition: all .3s linear;
        &:hover{
            color: #5A8BFF;
            transition: all .3s linear;
        }
    }
`