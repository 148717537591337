import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import {Form,Modal,Input,Button,Select,Upload,Icon,Col,message} from 'antd'
import Editor from 'wangeditor';
import { connect } from 'react-redux';


const Option = Select.Option;
const FormItem = Form.Item;
const { TextArea } = Input;

function beforeUpload(file) {
  if(file.type.indexOf("image") == -1) return message.error('上传文件类型为图片!');
  const isLt2M = file.size / 1024 / 1024 < 3;
  if (!isLt2M) {
    message.error('图片太大了，要小于3000kb!');
  }
  return isLt2M;
}


class ModelSourceForm extends React.Component{
	constructor(props){
		super(props);
		this.state={
			organizechoosedata:this.props.organizechoosedata,
			imageUrl:this.props.type =="add"?'':this.props.organizechoosedata.logoPath,
			imageUrl2:this.props.type =="add"?'':this.props.organizechoosedata.licencePath,
			imageUrl3:props.type =="add"?'':props.organizechoosedata.imagePaths[0], 
			regionId: this.props.type =="add"?null:this.props.organizechoosedata.regionType,
			orgNameId: this.props.type =="add"?null:this.props.organizechoosedata.enterpriseType,
			weUrl: this.props.type =="add"?'1':''+this.props.organizechoosedata.isOpenWeshow,
			editorHtml: this.props.type =="add"?'':''+this.props.organizechoosedata.htmlContent
		}
        this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
	}
	handleSubmit(e){
	    e.preventDefault();
	    this.props.form.validateFieldsAndScroll({first: true,force: true},(err, values) => {
	    	if (err) {
	        return;
	      }
	    	var obj = values;
	    	if((this.state.regionId==3 && this.state.orgNameId == 1) || (this.state.regionId==2 && this.state.orgNameId == 1)){
	    		if(!this.state.imageUrl) return message.error('请上传系统logo!');
	    		if(!this.state.imageUrl2) return message.error('请上传营业执照!');
	    		obj.logoPath = this.state.imageUrl;
	    		obj.licencePath = this.state.imageUrl2;
	    	} 
	    	if(this.state.orgNameId == 4){
	    		obj.imagePaths = [this.state.imageUrl3]; 
	    		obj.htmlContent = this.state.editorHtml;
	    	}
	      this.props.setValues(obj);       
	    });
	  }
	handleCancelCode(){
		let isShow = false;
		this.props.setIsShow(isShow);
	}
	handleChange(info,e,data){
	    if (info.file.status === 'done' && info.file.response && info.file.response.code == '0000' ) {
	      
	      	this.setState({ imageUrl: info.file.response.data[0] });
	    }
	}
	handleChange2(info){
	    if (info.file.status === 'done' && info.file.response && info.file.response.code == '0000' ) {
	    		this.setState({ imageUrl2: info.file.response.data[0] });
	    }
	}
	handleChange3(info){
	    if (info.file.status === 'done' && info.file.response && info.file.response.code == '0000' ) {
	    		this.setState({ imageUrl3: info.file.response.data[0] });
	    }
	}
	// 选择机构类型
	changeOrg(e,data){
		this.setState({ 
			regionId: data.key,
			orgNameId: null
		})
		
		if(this.props.form.getFieldValue("enterpriseType")){
			this.props.form.setFieldsValue({'enterpriseType': ""});
		}
	}
	// 选择企业名称
	changeOrgName(e,data){
		this.setState({
			orgNameId: data.key
		})
	}
	onSelectChangeIsOpenWeshow(val){
		this.setState({
			weUrl: val
		})
	}
	componentWillMount(){
		if(this.props.type != 'add'){
   			this.setState({
   				regionId: this.state.organizechoosedata.regionType
   			})
   		}
	}
	checkPhone(rule, value, callback){
	    if(!(/^[1][3,4,5,7,8][0-9]{9}$/.test(value))){ 
	        callback("手机号码有误 !");  
	    }  else {
	      callback();
	    }
	}
	checkWhiteSpace(rule, value, callback){
	    if(value.indexOf(" ") > -1){ 
	        callback("不能有空格!");  
	    }  else {
	      callback();
	    }
	 }
	componentDidMount() { 
      //判断modal是否需要显示
      setTimeout(()=>{
	      var editor = new Editor(document.querySelector("#editor"));
	      // 使用 onchange 函数监听内容的变化，并实时更新到 state 中
	      editor.customConfig.onchange = (html) => {
	        this.setState({
	          editorHtml: html,
	          editorText: editor.txt.text()
	        })
	      }
	      editor.create();
	      editor.txt.html(this.state.editorHtml);
      },200)
	      //获取真实dom，创新富文本编辑器
	       
	}
	render(){
		const regionType = this.props.userInfo.regionType;
		var orgList = [];
		if(regionType == 0 || regionType == 1 || !regionType){
			orgList = [{value: 3,text: "企业"},{value: 2,text: "政府"},{value: 1,text: "地域"},{value: 4,text: "食材溯源"}];
		}else if(regionType == 3){
			orgList = [{value: 3,text: "企业"},{value: 1,text: "地域"}];
		}else if(regionType == 2){
			orgList = [{value: 2,text: "政府"},{value: 1,text: "地域"}];
		}else if(regionType == 4){
			orgList = [{value: 4,text: "食材溯源"},{value: 1,text: "地域"}];
		}
	const orgTypeList2 = [{value: 2,text: "养殖户"},{value: 1,text: "畜牧水产局"}];
	const orgTypeList3 = [{value: 1,text: "租户"},{value: 2,text: "屠宰中心"},{value: 3,text: "分割中心"},{value: 4,text: "门店"}];
	const editorshow = this.state.orgNameId == 4?"block":"none";
			const formItemLayout = {
	      labelCol: {
	        xs: { span: 24 },
	        sm: { span: 6 },
	      },
	      wrapperCol: {
	        xs: { span: 24 },
	        sm: { span: 18 },
	      },
	    };
	    const formItemLayout1 = {
	      labelCol: {
	        xs: { span: 24 },
	        sm: { span: 8 },
	      },
	      wrapperCol: {
	        xs: { span: 24 },
	        sm: { span: 16 },
	      },
	    };
   		const imageUrl = this.state.imageUrl;
   		const imageUrl2 = this.state.imageUrl2;
   		const imageUrl3 = this.state.imageUrl3;
   		var orgTypeList = [];
   		if(this.state.regionId == 2){
   			orgTypeList = orgTypeList2;
   		}else if(this.state.regionId == 3){
   			orgTypeList = orgTypeList3;
   		}
   		if(this.props.type == "add" && this.props.selectedNodes.regionType){
   			orgList.forEach((item,index) => {
   				if(this.props.selectedNodes.regionType == 2 && item.value == 3){
   					item.disabled = true;
   				}else if(this.props.selectedNodes.regionType == 3 && item.value == 2){
   					item.disabled = true;
   				}else if(this.props.selectedNodes.regionType == 4 && item.value != 4){
   					item.disabled = true;
   				}
   			})
   		}
   		const { getFieldDecorator } = this.props.form;
		return (
			<Modal
			  width={'60%'}
			  title={this.props.modaltitle}
			  visible={this.props.isShow}
			  onCancel={this.handleCancelCode.bind(this)}
			  maskClosable={false}
			  destroyOnClose={true}
			  footer={null}
			  className="editClass"
			  style={{maxWidth: "1100px",minWidth: "900px"}}
			>
			  <Form className="login-form" onSubmit={(e)=>{this.handleSubmit(e)}}>
			  	<div className="ant-row fi">
			  		<div className="ant-col-24">
			  			<div className="ant-col-10">
				  			<FormItem required={true} {...formItemLayout} label="机构类型">
				  				{getFieldDecorator('regionType', {
				  					initialValue: this.props.type=="add"?"":JSON.parse(this.state.organizechoosedata.regionType),
					            	rules: [{ required: true, message: '请选择机构类型!' }],
					         		})(
				         			<Select placeholder="请选择机构类型" onChange={this.changeOrg.bind(this)} style={{ width: '100%' }} disabled={this.props.type=="add"?false:true}>
										{
							  				orgList.map((item,index) => {
								  				return (
								  					<Option value={item.value} key={item.value} disabled={item.disabled}>{item.text}</Option>
								  				)
							  				})
										}
						  			</Select>
						  		)}
				  			</FormItem>
				  		</div>
				  		<div className="ant-col-2"></div>
				  		{
				  			this.state.regionId == 1 || this.state.regionId == 4?"":
					  		<div className="ant-col-10">
					  			<FormItem required={true} {...formItemLayout} label="机构">
					  				{getFieldDecorator('enterpriseType', {
					  					initialValue: this.props.type=="add"?'':JSON.parse(this.state.organizechoosedata.enterpriseType),
						            	rules: [{ required: true, message: '请选择机构!' }],
						         		})(
						         			<Select placeholder="请选择机构" onChange={this.changeOrgName.bind(this)} style={{ width: '100%' }} disabled={this.props.type=="add"?false:true}>
								  			{
								  				orgTypeList.map((item,index) => {
								  					return (
								  						<Option value={item.value} key={item.value}>{item.text}</Option>
								  					)
								  				})
											}
							  				</Select>
						         	)}
					  			</FormItem>
					  		</div>
				  		}
			  		</div>
			  	</div>
			  	<div className="ant-row">
			    	<div className="ant-col-10">
			  			<FormItem required={true} {...formItemLayout} label="机构名称">
			  				{getFieldDecorator('regionName', {
			  					initialValue: this.state.organizechoosedata.regionName,
				            	rules: [{ required: true, message: '请输入机构名称!', whitespace: true }],
				         		})(
					        	<Input type="text" maxLength={50} placeholder="请输入机构名称" /> 
					        )}
					    </FormItem>
			  		</div>
			  		<div className="ant-col-2"></div>
			  		<div className="ant-col-10">
			  			<FormItem {...formItemLayout} label="联系电话">
				  			{getFieldDecorator('regionTelno', {
				  				initialValue: this.state.organizechoosedata.regionTelno,
				            	rules: [{ required: true, message: '请输入联系电话!', whitespace: true },{ validator: this.checkPhone.bind(this)}],
				         		})(
				         		<Input type="text" placeholder="请输入联系电话" />
					        )}
					    </FormItem>
			  		</div>
			    </div>
			    <div className="ant-row">
			    	<div className="ant-col-10">
			  			<FormItem required={true} {...formItemLayout} label="机构简称">
				  			{getFieldDecorator('regionCode', {
				  				initialValue: this.state.organizechoosedata.regionCode,
				            	rules: [{ required: true, message: '请输入机构简称!', whitespace: true },{ validator: this.checkWhiteSpace.bind(this)}],
				         		})(
				         		<Input type="text" placeholder="请输入机构简称" />
					        )}
					    </FormItem>
			  		</div>
			  		<div className="ant-col-2"></div>
			  		<div className="ant-col-10">
			  			<FormItem {...formItemLayout} label="机构描述">
				  			{getFieldDecorator('regionDesc', {
				  				initialValue: this.state.organizechoosedata.regionDesc,
				            	rules: [{ required: true, message: '请输入机构描述!', whitespace: true }],
				         		})(
				         		<TextArea placeholder="请输入机构描述" rows={2} />
					        )}
					    </FormItem>
			  		</div>
			    </div>
			    {
			    	(this.state.regionId==3 && this.state.orgNameId == 1) || (this.state.regionId==2 && this.state.orgNameId == 1)?
			    	<div>
				    	<div className="ant-row">
				    		<Col span={10}>
								    <FormItem label="系统logo(<3M)" {...formItemLayout1} required={true}>
						            	<Upload
									        className="avatar-uploader"
									        name="files"
									        showUploadList={false}
									        action="client/file/upload"
									        beforeUpload={beforeUpload}
									        onChange={this.handleChange.bind(this)}
									      >
									        {
									          imageUrl ?
									            <div><img src={imageUrl} alt="" className="avatar" /><Icon style={{position:' absolute',top: '120px'}} type="plus" className="avatar-uploader-trigger" /></div> :
									            <Icon type="plus" className="avatar-uploader-trigger" />
									        }
									      </Upload>
						        </FormItem>
							</Col>
							<Col span={2}></Col>
							<Col span={10}>
								<FormItem label="营业执照(<3M)" {...formItemLayout1} required={true}>
						            <Upload
									    className="avatar-uploader"
									    name="files"
									    showUploadList={false}
									    action="client/file/upload"
									    beforeUpload={beforeUpload}
									    onChange={this.handleChange2.bind(this)}
									    >
								        {
								          imageUrl2 ?
								            <div><img src={imageUrl2} alt="" className="avatar" /><Icon style={{position:' absolute',top: '120px'}} type="plus" className="avatar-uploader-trigger" /></div> :
								            <Icon type="plus" className="avatar-uploader-trigger" />
								        }
									</Upload>
						        </FormItem>
							</Col>
				    	</div>
				    	<div className="ant-row">
					    	<div className="ant-col-10">
					  			<FormItem required={true} {...formItemLayout} label="系统名称">
						  			{getFieldDecorator('sysName', {
						  				initialValue: this.state.organizechoosedata.sysName,
						            	rules: [{ required: true, message: '请定义系统名称!', whitespace: true }],
						         		})(
						         		<Input type="text"  placeholder="请定义系统名称" />
						         	)}
							    </FormItem>
					  		</div>
					  		<div className="ant-col-2"></div>
					  		<div className="ant-col-10">
					  			<FormItem {...formItemLayout} label="法人代表">
					  				{getFieldDecorator('legalPerson', {
					  					initialValue: this.state.organizechoosedata.legalPerson,
						            	rules: [{ required: true, message: '请输入法人代表名称!', whitespace: true }],
						         		})(
						         		<Input type="text"  placeholder="请输入法人代表名称" />
						         	)}
							    </FormItem>
					  		</div>
					    </div>
				    </div>
				    : 
				    <div>
				    	{
				    		this.state.regionId==3 && this.state.orgNameId == 4?
					    		<div className="ant-row">		
						    		<div className="ant-col-10">
						    			<FormItem {...formItemLayout} label="开通微商城">
									    	{getFieldDecorator('isOpenWeshow', {
									    		initialValue: this.state.organizechoosedata.isOpenWeshow || this.state.weUrl,
							            	rules: [{ required: true, message: '请选择用户状态!'}],
							         		})(
										        <Select  onChange={this.onSelectChangeIsOpenWeshow.bind(this)}>
												    <Option value="1">是</Option>
												    <Option value="0">否</Option>
												</Select>
										     )}
									    </FormItem>
						    		</div>
						    		<div className="ant-col-2"></div>
						    		{
						    			this.state.weUrl == 1?
						    			<div className="ant-col-10">
							    			<FormItem {...formItemLayout} label="微商城URL">
										    	{getFieldDecorator('wechatUrl', {
										    		initialValue: this.state.organizechoosedata.wechatUrl,
							            	rules: [{ required: true, message: '请输入微商城URL!'}],
							         		})(
										        <Input  placeholder="请输入微商城URL" />
										      )}
										    </FormItem>
							    		</div>
							    		:
							    		""
						    		}
						    	</div>
						    	:
						    	""
				    	}
				    </div>
			    }
			    {
			    	this.state.regionId && this.state.regionId!=1?
			    	<div>
				    	<div className="ant-row">
				    		<div className="ant-col-10">
					  			<FormItem required={true} {...formItemLayout} label="经度">
					  				{getFieldDecorator('longitude', {
					  					initialValue: this.state.organizechoosedata.longitude,
						            	rules: [{ required: true, message: '请输入经度!', whitespace: true },{ validator: this.checkWhiteSpace.bind(this)}],
						         		})(
						         		<Input type="text"  placeholder="请输入经度" />
						         	)}
							    </FormItem>
					  		</div>
					  		<div className="ant-col-2"></div>
					  		<div className="ant-col-10">
					  			<FormItem {...formItemLayout} label="纬度">
					  				{getFieldDecorator('latitude', {
					  					initialValue: this.state.organizechoosedata.latitude,
						            	rules: [{ required: true, message: '请输入纬度!', whitespace: true },{ validator: this.checkWhiteSpace.bind(this)}],
						         		})(
						         		<Input type="text"  placeholder="请输入纬度" />
						         	)}
							    </FormItem>
					  		</div>
				    	</div>
				    	<div className="ant-row">
				    		<div className="ant-col-10">
					  			<FormItem {...formItemLayout} label="地址">
					  				{getFieldDecorator('address', {
					  					initialValue: this.state.organizechoosedata.address,
						            	rules: [{ required: true, message: '请输入地址!', whitespace: true }],
						         		})(
						         		<Input type="text"  placeholder="请输入地址" />
						         	)}
							    </FormItem>
					  		</div>
				    	</div>
				    	
				    		{
						    	this.state.orgNameId == 4?
						    	<div className="ant-row">
						    		<div className="ant-col-10">
									    <FormItem label="门店照片" {...formItemLayout} required={true}>
						            	<Upload
									        className="avatar-uploader"
									        name="files"
									        showUploadList={false}
									        action="client/file/upload"
									        beforeUpload={beforeUpload}
									        onChange={this.handleChange3.bind(this)}
									      >
									        {
									          imageUrl3 ?
									            <div><img src={imageUrl3} alt="" className="avatar" /><Icon style={{height: "150px",width: "150px",position: "absolute",top: "0",lineHeight: "150px"}} type="plus" className="avatar-uploader-trigger" /></div> :
									            <Icon type="plus" className="avatar-uploader-trigger" />
									        }
									      </Upload>
							        </FormItem>
										</div>
									</div>
									: 
									""
						    }
				    </div>
				    :
				    ""
			    }
			    <div className="ant-row" style={{display: editorshow}}>
			    	<h4>门店详情:</h4>	
						<div id="editor"></div>	
					</div>
			    <div className="ant-row">
			    	<FormItem>
				      <div style={{textAlign:'center'}}><Button type="primary" htmlType="submit" style={{width:'30%',marginTop: "15px"}}>
				        提交
				      </Button></div>
				    </FormItem>
			    </div>
			  </Form>
			</Modal>
		)
	}
}
// mapStateToProps：将state映射到组件的props中
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}
// mapDispatchToProps：将dispatch映射到组件的props中
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
  }
}

const ModelSource = Form.create()(ModelSourceForm);
export default connect(mapStateToProps, mapDispatchToProps)(ModelSource);


