import request from '../https'

class All {
    //获取菜单列表
    getMenu() {
        return request({
            url: 'client/sys/getLoginMenu',
            method: 'post',
        })
    }
    
    //获取当前登录用户
    getUser() {
        return request({
            url: 'client/sys/getLoginUser',
            method: 'post',
        })
    }
}

export default All