import React, {useState, useEffect} from 'react'

import { connect } from 'react-redux'

import { Container } from './style'

import {Button} from 'antd'

function PowerButton(props) {
    const { pageId, powerButtonList, btnType, onClick, title, type, size } = props
    const [btnGroup, setBtnGroup] = useState([])
    useEffect(() => {
        getBtnGroupByPageId()
    }, [])
    // 获取pageId页面 的权限按钮组
    const getBtnGroupByPageId = () => {
        let list = []
        powerButtonList.forEach(item => {
            if (item.pageId === pageId && item.permission && item.permission !== '') {
                list.push(item.permission.split('-')[0])
            }
        })
        setBtnGroup(list)
    }
    return (
        <React.Fragment>
            {
                btnGroup.includes(btnType) ? <Button type={type || 'primary'} size={size} onClick={onClick}>{title}</Button> : ''
            }
        </React.Fragment>
    )
}

const mapState = (state) => ({
    powerButtonList: state.powerButtonList
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(PowerButton)