import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import { Row, Col, Tree, Divider, Button, Table, Input, message } from 'antd'
import SystemRequest from '../../../request/system-request'
import ModalSource from './subpage/index'
import PowerButton from '../../../component/PowerButton/index'
import { Container } from './style'
import DeTable from '../../../component/DeTable/index'

const Search = Input.Search;
const TreeNode = Tree.TreeNode;
const sys_request = new SystemRequest()
class Organize extends React.Component {
	constructor(props) {
		super(props);
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
		this.state = {
			organlist: [],
			tdata: [],
			selectedNodes: null,
			treeNode: null,
			treeNodeId: null,
			tcolumn: [],
			showTable: false,
			isShow: false,
			modaltitle: '',
			tid: '',
			pid: '',
			type: 'add',
			organizechoosedata: {},
			searchname: '',
			urlpid: '',
			currentPage: 1
		}
	}
	componentWillMount() {
		this.setState({ urlpid: this.props.pageId})
		sys_request.organizeList({
			parentId: ''
		}).then((res) => {
			this.setState({
				organlist: res
			}, () => {
				this.onSelect([this.state.organlist[0].regionId], null, this.state.organlist[0])
			})
		})
	}
	onLoadData(treeNode) {
		let id = treeNode.props.eventKey;
		this.setState({
			treeNodeId: id,
			treeNode: treeNode
		})
		return new Promise((resolve) => {
			if (treeNode.props.children) {
				resolve();
				return;
			}
			setTimeout(() => {
				sys_request.organizeList({
					parentId: id
				}).then((res) => {
					if (res) {
						treeNode.props.dataRef.children = [...res];
						this.setState({
							organlist: [...this.state.organlist],
						});
						resolve();
					}

				})

			}, 500);
		});

	}
	renderTreeNodes(data) {
		return data.map((item) => {
			if (item.children) {
				return (
					<TreeNode title={item.regionName} key={item.regionId} dataRef={item}>
						{this.renderTreeNodes(item.children)}
					</TreeNode>
				);
			}
			return <TreeNode title={item.regionName} key={item.regionId} dataRef={item} />;
		});
	}

	getData(selectedKeys) {
		sys_request.organizeChildList({
			// pageSize: this.state.table.pageSize,
			// pageNo: this.state.table.pageNo,
			regionId: selectedKeys,
			regionName: this.state.searchname
		}).then((res) => {
			this.setState({
				// table: {
				// 	total: res.total,
				// 	pageSize: this.state.table.pageSize,
				// 	pageNo: this.state.table.pageNo
				// },
				currentPage: 1,
				tdata: res.list,
				tcolumn: [{
					title: '序号',
					render: (text, record, index) => (
						<span>{index + 1}</span>
					),
					dataIndex: 'number',
				},{
					title: '机构名称',
					dataIndex: 'regionName',
					width: '25%'
				}, {
					title: '机构代码',
					dataIndex: 'regionCode',
					width: '25%'
				}, {
					title: '电话号码',
					dataIndex: 'regionTelno'
				}, {
					title: '操作',
					render: (text, record, index) => (<span><PowerButton
						pageId={this.state.urlpid}
						btnType='update'
						size="small"
						onClick={this.singleEdit.bind(this, record, index)}
						title='编辑' >
					</PowerButton>
						<Divider type="vertical" />
						<PowerButton
							pageId={this.state.urlpid}
							btnType='delete'
							type="danger"
							size="small"
							onClick={this.singleDel.bind(this, record, index)}
							title='删除' >
						</PowerButton></span>)
				}]
			})
		})
	}
	onSelect(selectedKeys, info) {
		if (selectedKeys != "") {
			this.setState({
				showTable: true,
				pid: selectedKeys[0]
			})
			if (info) {
				this.setState({
					selectedNodes: info.selectedNodes[0].props.dataRef
				})
			}
			this.getData(selectedKeys[0]);
		}
	}
	singleEdit(record, index) {
		sys_request.organizeDetail(record.regionId).then(res => {
			this.setState({
				isShow: true,
				tid: record.regionId,
				type: 'edit',
				modaltitle: '编辑组织',
				organizechoosedata: res
			})
		})

	}
	singleDel(record, index) {
		sys_request.deleteOrganize(record.regionId).then((res) => {
			this.getData(this.state.pid);
			this.getLeftOrgList();
		})

	}
	setIsShow(callback) {
		this.setState({
			isShow: callback
		})
	}
	getLeftOrgList() {
		sys_request.organizeList({
			parentId: this.state.treeNodeId || ""
		}).then((res) => {
			if (this.state.treeNode) {
				this.state.treeNode.props.dataRef.children = [...res];
				this.setState({
					organlist: [...this.state.organlist]
				})
			} else {
				this.setState({
					organlist: res
				})
			}
		})
	}
	setValues(callback) {
		if (this.state.type === 'add') {
			callback.parentId = this.state.tid;
			sys_request.addNewOrginize(JSON.stringify(callback)).then((res) => {
				this.onSelect([this.state.pid])
				message.success('新增保存成功!');
				this.getLeftOrgList();
				this.setIsShow(false);
			})
		} else {
			callback.regionId = this.state.tid;
			sys_request.updateOrganize(JSON.stringify(callback)).then((res) => {
				this.onSelect([this.state.pid])
				message.success('编辑保存成功!');
				this.getLeftOrgList();
				this.setIsShow(false);
			})
		}

	}
	addNewModal() {
		if (!this.state.selectedNodes) return message.error("请选择上级机构!");
		this.setState({
			isShow: true,
			tid: this.state.pid,
			type: 'add',
			modaltitle: '新增组织',
			organizechoosedata: ''
		})
	}
	searchOranize(value) {
		this.setState({
			searchname: value
		}, () => {
			this.onSelect([this.state.pid]);
		})
	}
	render() {
		return (
			<Container>
				<Row gutter={16} style={{height: '100%'}}>
					<Col span={4} style={{ borderRight: '1px solid #ddd', height: '100%', overflow: 'auto' }}>
						<Tree loadData={this.onLoadData.bind(this)} showLine onSelect={this.onSelect.bind(this)} style={{ height: '100%' }}>
							{this.renderTreeNodes(this.state.organlist)}
						</Tree>
					</Col>
					<Col span={20} style={{height: '100%'}}>
						{
							this.state.showTable
								?
								<React.Fragment>
									<div style={{ margin: '0 0 10px 0' }}>
										<PowerButton
											pageId={this.state.urlpid}
											btnType='add'
											onClick={this.addNewModal.bind(this)}
											title='新增' >
										</PowerButton> <Search
											placeholder="请输入机构名称"
											onSearch={value => this.searchOranize(value)}
											enterButton
											style={{ width: '200px' }}
										/>
									</div>
									<div className="bottom">
										<DeTable dataSource={this.state.tdata} columns={this.state.tcolumn} pagination={{ current: this.state.currentPage, onChange: (page) => this.setState({currentPage: page}), showSizeChanger: true, showTotal: (total) => `共 ${total} 条` }}></DeTable>
									</div>
								</React.Fragment>
								:
								''
						}

					</Col>
				</Row>
				{
					this.state.isShow ?
						<ModalSource type={this.state.type} selectedNodes={this.state.selectedNodes} organizechoosedata={this.state.organizechoosedata} isShow={this.state.isShow} setValues={this.setValues.bind(this)} setIsShow={this.setIsShow.bind(this)} modaltitle={this.state.modaltitle}></ModalSource>
						:
						""
				}

			</Container>
		)
	}
}

export default Organize