import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import { Form, Modal, Input, Button, Row, Col, Select, DatePicker, Icon, message, Checkbox } from 'antd'
import moment from 'moment'
import SystemRequest from '../../../../request/system-request'
import '../style.css'

const FormItem = Form.Item;
const sys_request = new SystemRequest()
const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;

function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

class ModalSourceForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			organizechoosedata: this.props.organizechoosedata,
			loading: false,
			imageUrl: '',
			imageUrl2: '',
			imageUrl3: '',
			roleList: []
		}
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
	}
	componentDidMount() {
		sys_request.sysUserRoleList().then((res) => {
			this.setState({
				roleList: res
			})
		})
	}
	componentDidUpdate() {
	}
	handleSubmit(e) {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll({ first: true, force: true }, (err, values) => {
			if (err) {
				if (this.props.type == "add") {
					return;
				} else {
					if (err.endDate || err.password || err.userType) {
					} else {
						return;
					}
				}
			}
			let callbackval = {
				enterpriseName: values.enterpriseName,
				account: values.account,
				password: values.password,
				userType: values.userType,
				userState: values.userState,
				nikeName: values.nikeName,
				linkName: values.linkName,
				linkPohone: values.linkPohone,
				address: values.address
			};
			if (this.props.organizechoosedata.userState === 1) {
				callbackval.beginDate = moment(values.endDate[0]).format('YYYY-MM-DD');
				callbackval.endDate = moment(values.endDate[1]).format('YYYY-MM-DD');
			}
			if (values.photoUrl) {
				if (values.photoUrl.file.response.code === '0000') {
					callbackval.photoUrl = values.photoUrl.file.response.data[0];
				}
			}
			if (this.props.enterpriseType == "4") {
				callbackval.openId = values.openId;
			}
			values.roleIdList = values.roleIdList.filter(function (val, i, t) {
				return val != false;
			});
			callbackval.roleIdList = values.roleIdList
			if (values.userType == '1') {
				callbackval.creditCode = values.creditCode;
				callbackval.legalPerson = values.legalPerson;
				callbackval.registerCapital = values.registerCapital;
				callbackval.sysName = values.sysName;
				callbackval.longitude = values.longitude;
				callbackval.latitude = values.latitude;
				callbackval.isOpenWeshow = values.isOpenWeshow * 1;
				if (values.isOpenWeshow == '1') {
					callbackval.wechatUrl = values.wechatUrl;
				}
				if (values.licencePath) {
					if (values.licencePath.file.response.code === '0000') {
						callbackval.licencePath = values.licencePath.file.response.data[0]
					}
				}
				if (values.logoPath) {
					if (values.logoPath.file.response.code === '0000') {
						callbackval.logoPath = values.logoPath.file.response.data[0];
					}
				}
			}
			this.props.setValues(callbackval);
			//this.handleCancelCode();  
			//this.clearImg();
			//this.props.form.resetFields(); 
		});
	}
	//清空图像
	clearImg() {
		this.setState({
			imageUrl: '',
			imageUrl2: '',
			imageUrl3: ''
		})
	}
	handleCancelCode() {
		let isShow = false;
		this.props.setIsShow(isShow);
		this.clearImg();
		this.props.form.resetFields();
	}
	//操作有效无效控件
	onSelectChange(val) {
		this.props.organizechoosedata.userState = val * 1
	}
	//操作有效无效控件
	onSelectChangeManage(val) {
		this.props.organizechoosedata.manage = val * 1
	}
	
	//操作是否开通微商城
	onSelectChangeIsOpenWeshow(val) {
		this.props.organizechoosedata.isOpenWeshow = val * 1
	}
	//改变用户类型
	changeType(val) {
		this.props.organizechoosedata.userType = val * 1
	}

	//上传营业执照
	handleChange2(info) {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => this.setState({ imageUrl2: imageUrl }));
		}
	}
	checkPhone(rule, value, callback) {
		const form = this.props.form;
		const phone = form.getFieldValue('linkPohone')
		if (!(/^1[34578]\d{9}$/.test(phone))) {
			callback("手机号码有误，请重填");
		} else {
			//将手机验证码变为可用
			this.setState({
				canGetCode: false
			})
			callback();
		}
	}
	//上传头像
	handleChange(info) {
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(info.file.originFileObj, imageUrl => this.setState({ imageUrl }));
		}
	}
	//上传logo
	handleChange3(info) {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => this.setState({ imageUrl3: imageUrl }));
		}
	}
	//选择角色列表
	onChangeRoleList(checkedValues) {
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		const dateFormat = "YYYY/MM/DD"
		const uploadButton = (
			<div>
				<Icon type={this.state.loading ? 'loading' : 'plus'} />
				<div className="ant-upload-text">Upload</div>
			</div>
		);
		const imageUrl = this.state.imageUrl;
		const imageUrl2 = this.state.imageUrl2;
		const imageUrl3 = this.state.imageUrl3;
		let defaultCheckValue = [];
		if (this.props.organizechoosedata.tSysUserRoleList) {
			defaultCheckValue = this.props.organizechoosedata.tSysUserRoleList.map((val) => {
				if (val.isSelect == 1) {
					return val.roleId
				} else {
					return false;
				}
			})
		}

		let usertypelist = [];
		for (let i = 0; i < this.props.usertypelist.length; i++) {
			usertypelist.push(<Option key={i + 1} value={this.props.usertypelist[i].dictValue}>{this.props.usertypelist[i].dictDesc}</Option>)
		}
		return (
			<Modal
				title={this.props.modaltitle}
				visible={this.props.isShow}
				onCancel={this.handleCancelCode.bind(this)}
				maskClosable={false}
				destroyOnClose={true}
				footer={null}
				width='60%'
				style={{ minWidth: '800px', maxWidth: '1000px' }}
			>
				<Form style={{ padding: "24px" }} onSubmit={(e) => { this.handleSubmit(e) }} className="ant-advanced-search-form" id="adduserform">
					<Row gutter={24} id="inputVal">
						<Col span={12}>
							<FormItem label="用户名称">
								{getFieldDecorator('enterpriseName', {
									initialValue: this.props.type === 'add' ? '' : this.props.organizechoosedata.enterpriseName,
									rules: [{ required: true, message: '请输入用户名称!' }],
								})(
									<Input type="text" maxLength={50} placeholder="请输入用户名称" />
								)}

							</FormItem>
						</Col>
						{
							this.props.type === 'add' ?
								<Col span={12} >
									<FormItem label="用户类型">
										{getFieldDecorator('userType', {
											initialValue: this.props.regionName,
											rules: [{ required: this.props.type != 'add' ? true : false, message: '请选择用户类型' }],
										})(
											<Input type="text" disabled={true}></Input>
										)}
									</FormItem>
								</Col>
								: ""
						}
						<Col span={12}>
							<FormItem label="用户账号">
								{getFieldDecorator('account', {
									initialValue: this.props.type === 'add' ? '' : this.props.organizechoosedata.account,
									rules: [{ required: true, message: '请输入用户账号!' }],
								})(
									<Input type="text" disabled={this.props.type === 'edit' ? true : false} placeholder="请输入用户账号" />
								)}

							</FormItem>
						</Col>
						{
							this.props.type === 'add' ?
								<Col span={12} >
									<FormItem label="密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码">
										{getFieldDecorator('password', {
											initialValue: '',
											rules: [{ required: this.props.type != 'add' ? true : false, message: '请输入密码' }],
										})(
											<Input type={"text"} placeholder="请输入密码" />
										)}
									</FormItem>
								</Col>
								: ""
						}

						{
							this.props.enterpriseType == 4 ?
								<Col span={12} >
									<FormItem label="openId">
										{getFieldDecorator('openId', {
											initialValue: this.props.type === 'add' ? '' : this.props.organizechoosedata.openId,
										})(
											<Input type={"text"} placeholder="请输入openId" />
										)}
									</FormItem>
								</Col>
								: ""
						}

						<Col span={12}>
							<FormItem label="用户状态">
								{getFieldDecorator('userState', {
									initialValue: this.props.type === 'add' ? '1' : '' + this.props.organizechoosedata.userState + '',
									rules: [{ required: true, message: '请选择用户状态!' }],
								})(
									<Select onChange={this.onSelectChange.bind(this)}>
										<Option value="1">有效</Option>
										<Option value="0">无效</Option>
									</Select>
								)}

							</FormItem>
						</Col>
						
							<Col span={12}>
							<FormItem label="是否洗消管理员">
								{getFieldDecorator('manage', {
									initialValue: this.props.type === 'add' ? '1' : '' + this.props.organizechoosedata.manage + '',
									 
								})(
									<Select onChange={this.onSelectChangeManage.bind(this)}>
										<Option value="1">有</Option>
										<Option value="">否</Option>
									</Select>
								)}

							</FormItem>
						</Col>
						
						{this.props.organizechoosedata.userState === 1 ? <Col span={12} >
							<FormItem label="有效日期">
								{getFieldDecorator('endDate', {
									initialValue: this.props.type === 'add' ? [] : [moment(moment(this.props.organizechoosedata.beginDate), dateFormat), moment(moment(this.props.organizechoosedata.endDate), dateFormat)],
									rules: [{ type: 'array', required: this.props.type != 'add' ? true : false, message: '请选择日期' }],
								})(
									<RangePicker />
								)}
							</FormItem>
						</Col> : ''
						}
						<Col span={12} >
							<FormItem label="&nbsp;&nbsp;昵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称">
								{getFieldDecorator('nikeName', {
									initialValue: this.props.type === 'add' ? '' : this.props.organizechoosedata.nikeName,
								})(
									<Input type="text" placeholder="请输入昵称" />
								)}
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label="联&nbsp;系&nbsp;人">
								{getFieldDecorator('linkName', {
									initialValue: this.props.organizechoosedata.linkName,
									rules: [{ required: true, message: '请输入联系人!' }],
								})(
									<Input placeholder="请输入联系人" />
								)}
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label="联系电话">
								{getFieldDecorator('linkPohone', {
									initialValue: this.props.organizechoosedata.linkPohone,
									rules: [{ required: true, message: '请输入联系电话!' }, { validator: this.checkPhone.bind(this) }],
								})(
									<Input placeholder="请输入联系电话" />
								)}
							</FormItem>
						</Col>
						<Col span={12}>
							<FormItem label="用户地址">
								{getFieldDecorator('address', {
									initialValue: this.props.organizechoosedata.address,
								})(
									<Input placeholder="请输入用户地址" />
								)}
							</FormItem>
						</Col>
						<Col span={16} style={{ marginTop: '15px' }}>
							<FormItem label="选择角色">
								{getFieldDecorator('roleIdList', {
									initialValue: this.props.type === 'add' ? [] : defaultCheckValue,
									rules: [{ required: true, message: '请选择用户状态!' }],
								})(
									<Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeRoleList.bind(this)}>
										{
											this.props.type === 'add'
												?
												<Row>
													{this.state.roleList.map((item, index) => {
														return <Col key={index} span={8}><Checkbox value={item.roleId}>{item.roleName}</Checkbox></Col>
													})}
												</Row>
												:
												<Row>
													{this.props.organizechoosedata.tSysUserRoleList.map((item, index) => {
														return <Col key={index} span={8}><Checkbox value={item.roleId}>{item.roleName}</Checkbox></Col>
													})}
												</Row>
										}
									</Checkbox.Group>
								)}

							</FormItem>
						</Col>
						<Col span={24}>
							<FormItem>
								<div style={{ textAlign: 'center' }}><Button type="primary" htmlType="submit" style={{ width: '30%' }}>
									提交
					      </Button></div>
							</FormItem>
						</Col>
					</Row>

				</Form>
			</Modal>
		)
	}
}
const ModalSource = Form.create()(ModalSourceForm);
export default ModalSource;


