import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import { Modal, Button, Tree, Card, Divider } from 'antd'
const TreeNode = Tree.TreeNode;
class ModalPower extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			organizechoosedata: this.props.organizechoosedata,
			powerlist: [],
			autoExpandParent: true,
			checkedKeys: [],
			checkPowerId: '',
			selectedKeys: []
		}
		this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
	}
	componentWillReceiveProps(props) {
		this.setState({
			checkedKeys: props.checkedKeys
		})

	}
	handleSubmit(e) {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (err) {
				return;
			}
			this.props.setValues(values);
			this.handleCancelCode();
			this.props.form.resetFields();
		});
	}
	handleCancelCode() {
		let isShow = false;
		this.props.setIsShow(isShow);
	}
	onExpand(expandedKeys) {
		this.setState({
			expandedKeys,
			autoExpandParent: false,
		});
	}
	onCheck(checkedKeys, info) {
		let tmpdata = [...checkedKeys, ...info.halfCheckedKeys]
		this.setState({ checkedKeys, checkPowerId: tmpdata });
	}
	onSelect(selectedKeys, info) {
		//this.setState({ selectedKeys });
	}
	renderTreeNodes(data) {
		return data.map((item) => {
			if (item.childMenu) {
				return (
					<TreeNode title={item.itemName} key={item.itemId} dataRef={item}>
						{this.renderTreeNodes(item.childMenu)}
					</TreeNode>
				);
			}
			return <TreeNode title={item.itemName} key={item.itemId} dataRef={item} />;
		});
	}
	setPower() {
		if (this.state.checkPowerId.length === 0) {
			this.props.setPower(this.state.checkedKeys)
		} else {
			this.props.setPower(this.state.checkPowerId)
		}
		this.setState({
			checkedKeys: [],
			checkPowerId: ''
		})
		this.handleCancelCode();
	}
	render() {
		return (
			<Modal
				title='权限设置'
				visible={this.props.isShow}
				onCancel={this.handleCancelCode.bind(this)}
				maskClosable={false}
				destroyOnClose={true}
				footer={null}
				width='680px'
			>
				<div style={{ marginBottom: '5px' }}><span>角色名称：</span><span>{this.props.organizechoosedata.roleName}</span></div>
				<Card >
					<Tree
						showLine
						checkable
						onExpand={this.onExpand.bind(this)}
						autoExpandParent={this.state.autoExpandParent}
						onCheck={this.onCheck.bind(this)}
						checkedKeys={this.state.checkedKeys}
						onSelect={this.onSelect.bind(this)}
						selectedKeys={this.state.selectedKeys}
					>
						{this.renderTreeNodes(this.props.powerData)}
					</Tree>
					<Divider />
					<div style={{ textAlign: 'center', marginBottom: '5px' }}>
						<Button type="primary" onClick={this.setPower.bind(this)} >提交</Button>
					</div>
				</Card>

			</Modal>
		)
	}
}
export default ModalPower;


