import React, { useState, useEffect } from 'react'
import { Container, LoginBox, Footer } from './style'

import { connect } from 'react-redux'
import { Button, Input, Form, Checkbox, Icon,message} from 'antd'
import Post from '../../request/system-request.js'

const _post = new Post();
function Login(props) {
    const { getFieldDecorator } = props.form
    const [randomTime, setRandomTime] = useState('') //随机数 用于验证码刷新
    const [isRotate, setIsRotate] = useState(false) //验证码刷新按钮旋转
    const [isLogin, setIsLogin] = useState(true) //当前为登录状态还是重置密码状态
    useEffect(() => {
    })
    //登录
    const loginNow = (e) => {
        e.preventDefault()
        	 props.form.validateFields((err, values) => {
	    if (!err) {
				_post.getLogin({
					userAccount:values.username,
					userPassword:values.password
				}).then((res)=>{ 
				props.history.push('/home')
					
				},(errMsg)=>{

				 
					 
				})
	    }else{
	    	 
	    }
	  });
//      this.setState({
//			loading:true
//		})
	  //e.preventDefault();
//	  this.props.form.validateFields((err, values) => {
//	
//	  });
        
    }
    //刷新验证码
    const refreshImg = () => {
        setRandomTime(Math.random().toFixed(9))
        setIsRotate(true)
        setTimeout(() => {
            setIsRotate(false)
        }, 500);
    }
    //忘记密码
    const forgetPassword = () => {
        setIsLogin(false)
    }
    //返回登录界面
    const backToLogin = () => {
        setIsLogin(true)
    }
    return (
        <Container>
            <LoginBox>
                {
                    isLogin ? 
                        <Form className="login-form" onSubmit={loginNow}>
                            <Form.Item>
                                <div className="title">生物安全防控管理系统</div>
                            </Form.Item>
                            <Form.Item style={{ width: '100%', textAlign: 'center' }}>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: '请输入您的用户名!' }]
                                })(
                                    <Input style={{ width: '70%' }} size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="请输入您的用户名" />
                                )}
                            </Form.Item>
                            <Form.Item style={{ width: '100%', textAlign: 'center' }}>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: '请输入您的密码!' }]
                                })(
                                    <Input style={{ width: '70%' }} size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请输入您的密码" />
                                )}
                            </Form.Item>
                            <Form.Item style={{ width: '70%', textAlign: 'left' }}>
                                {getFieldDecorator('code', {
                                    rules: [{ required: true, message: '请输入验证码！' }],
                                })(
                                    <Input
                                        placeholder="请输入验证码"
                                        size="large"
                                        prefix={<Icon type="message" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        style={{ width: '50%', marginRight: 10 }}
                                    />,
                                )}
                                <span onClick={refreshImg} style={{ width: '50%' }}>
                                    <React.Fragment>
                                        <img src={"client/validate/code?time=" + randomTime} className="code" alt="获取验证码失败" />
                                        <Icon style={{ marginLeft: 10 }} spin={isRotate} type="sync"></Icon>
                                    </React.Fragment>
                                </span>
                            </Form.Item>
                            <Button type="primary" style={{ width: '70%' }} htmlType="submit" size="large">登录</Button>
                            <Form.Item style={{marginTop: 10, width: '70%', textAlign: 'right'}}>
                                {getFieldDecorator('remember', {
                                    valuePropName: 'checked',
                                    initialValue: false
                                })(
                                    <Checkbox>记住帐号</Checkbox>
                                )}
                                <Button type="link" onClick={forgetPassword}>忘记密码</Button>
                            </Form.Item>
                        </Form>
                    :
                        <Form className="login-form">
                            <Button shape="circle" icon="arrow-left" onClick={backToLogin} className="back-to-login" />
                            <Form.Item>
                                <div className="title">重置密码</div>
                            </Form.Item>
                            <Form.Item style={{ width: '70%', textAlign: 'left' }}>
                                {getFieldDecorator('phone', {
                                    rules: [{ required: true, message: '请输入您的手机号!' }]
                                })(
                                    <Input style={{ width: '55%', marginRight: 10 }} size="large" prefix={<Icon type="mobile" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="请输入您的手机号" />
                                )}
                                <Button size="large" disabled type="primary">23s 发送验证码</Button>
                            </Form.Item>
                            <Form.Item style={{ width: '100%', textAlign: 'center' }}>
                                {getFieldDecorator('phonecode', {
                                    rules: [{ required: true, message: '请输入手机验证码!' }]
                                })(
                                    <Input style={{ width: '70%' }} size="large" prefix={<Icon type="message" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请输入手机验证码" />
                                )}
                            </Form.Item>
                            <Form.Item style={{ width: '100%', textAlign: 'center' }}>
                                {getFieldDecorator('loginpassword', {
                                    rules: [{ required: true, message: '请设置登录密码！' }]
                                })(
                                    <Input style={{ width: '70%' }} size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请设置登录密码" />
                                )}
                            </Form.Item>
                            <Form.Item style={{ width: '100%', textAlign: 'center' }}>
                                {getFieldDecorator('confirmpassword', {
                                    rules: [{ required: true, message: '请确认登录密码！' }]
                                })(
                                    <Input style={{ width: '70%' }} size="large" prefix={<Icon type="check" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="请确认登录密码" />
                                )}
                            </Form.Item>
                            <Button type="primary" style={{ width: '70%' }} onClick={loginNow} size="large">确认修改</Button>
                        </Form>
                }
            </LoginBox>
            <Footer>- 本系统由润农科技有限公司提供技术支持 -</Footer>
        </Container>
    )
}

const mapState = (state) => ({
})

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(Form.create()(Login))