import React, { useEffect, useState } from 'react'

import { Container } from './style'

import echarts from '../../echarts_init'

import HomeRequest from '../../request/home'

const _request = new HomeRequest()

function ErrorPage() {
    useEffect(() => {
        getEcharts1Data()
        getEcharts2Data()
        getEcharts3Data()
        getEcharts4Data()
    }, [])
    const getEcharts1Data = () => {
        _request.getEcharts1Data().then(res => {
            let date = []
            let waterData = []
            let electricData = []
            res.meterEchartsArr.forEach(item => {
                date.push(item.date)
                waterData.push(item.value)
            })
            res.electricEchartsArr.forEach(item => {
                electricData.push(item.value)
            })
            initEcharts1(date, waterData, electricData)
        })
    }
    const getEcharts2Data = () => {
        _request.getEcharts2Data({
            queryMotnth: '1'
        }).then(res => {
            let date = []
            let carData = []
            let peopleData = []
            let objectData = []
            res.carEchartsArr.forEach(item => {
                date.push(item.date)
                carData.push(item.count)
            })
            res.peopleEchartsArr.forEach(item => {
                peopleData.push(item.count)
            })
            res.goodEchartsArr.forEach(item => {
                objectData.push(item.count)
            })
            initEcharts2(date, carData, peopleData, objectData)
        })
    }
    const getEcharts3Data = () => {
        _request.getEcharts3Data().then(res => {
            let date = []
            let carData = []
            let peopleData = []
            let objectData = []
            res.carEchartsArr.forEach(item => {
                date.push(item.date)
                carData.push(item.count)
            })
            res.peopleEchartsArr.forEach(item => {
                peopleData.push(item.count)
            })
            res.goodEchartsArr.forEach(item => {
                objectData.push(item.count)
            })
            initEcharts3(date, carData, peopleData, objectData)
        })
    }
    const getEcharts4Data = () => {
        _request.getEcharts4Data().then(res => {
            let date = []
            let carData = []
            let objectData = []
            res.carEchartsArr.forEach(item => {
                date.push(item.date)
                carData.push(item.count)
            })
            res.goodEchartsArr.forEach(item => {
                objectData.push(item.count)
            })
            initEcharts4(date, carData, objectData)
        })
    }

    const initEcharts1 = (date, waterData, electricData) => {
        echarts.init(document.getElementById('charts-1'), 'co-op-theme').setOption({
            legend: {
                data: ['水表', '电表'],
				textStyle: {
				  color:"#fff"
				}
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params[0].name + '月</br>' + params[0].seriesName + '：' + (params[0].data === 'null' ? '-' : (params[0].data + '吨')) + '</br>' + params[1].seriesName + '：' + (params[1].data === 'null' ? '-' : (params[1].data + '度'))
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '80%',
                width: '80%',
                y2: 0,
                x: '10%'
            },
            yAxis: [
                {
                    type: 'value',
                    name: '水表（吨）',
					axisLabel: {
						color:"#fff"
                    },
                    axisLine:{
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
                {
                    type: 'value',
                    name: '电表（度）',
					axisLabel: {
						color:"#fff"
                    },
                    axisLine:{
                        lineStyle:{
                            color:'#fff'
                        }
                    },
                },
            ],
            xAxis: {
                type: 'category',
                data: date,
                name: '月份',
				axisLine: {
					show: true,
					lineStyle: {
						color: "#fff"
					}
				},
                axisLabel: {
                    interval: 0,
					color:"#fff"
                },
            },
            series: [
                {
                    name: '水表',
                    type: 'line',
                    yAxisIndex: 0,
                    label: {
                        normal: {
                            show: true,
                            position: 'bottom'
                        }
                    },
                    data: waterData
                },
                {
                    name: '电表',
                    type: 'line',
                    yAxisIndex: 1,
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: electricData
                },
            ]
        })
    }
    const initEcharts2 = (date, carData, peopleData, objectData) => {
        echarts.init(document.getElementById('charts-2'), 'co-op-theme').setOption({
            legend: {
                data: ['车辆', '人员', '物资'],
				textStyle: {
				  color:"#fff"
				}
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params[0].name + '月</br>' + params[0].seriesName + '：' + (params[0].data === 'null' ? '-' : params[0].data) + '</br>' + params[1].seriesName + '：' + (params[1].data === 'null' ? '-' : params[1].data)
                        + '</br>' + params[2].seriesName + '：' + (params[2].data === 'null' ? '-' : params[2].data)
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '80%',
                width: '80%',
                y2: 0,
                x: '10%'
            },
            yAxis: {
                type: 'value',
                name: '数量',
				axisLabel: {
					color:"#fff"
                },
                axisLine:{
                    lineStyle:{
                        color:'#fff'
                    }
                },
				
            },
            xAxis: {
                type: 'category',
                data: date,
                name: '月份',
				axisLine: {
					show: true,
					lineStyle: {
						color: "#fff"
					}
				},
                axisLabel: {
                    interval: 0,
					color:"#fff"
                },
            },
            series: [
                {
                    name: '车辆',
                    type: 'line',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: carData
                },
                {
                    name: '人员',
                    type: 'line',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: peopleData
                },
                {
                    name: '物资',
                    type: 'line',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: objectData
                }
            ]
        })
    }
    const initEcharts3 = (date, carData, peopleData, objectData) => {
        echarts.init(document.getElementById('charts-3'), 'co-op-theme').setOption({
            legend: {
                data: ['车辆', '人员', '物资'],
				textStyle: {
				  color:"#fff"
				}
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params[0].name + '月</br>' + params[0].seriesName + '：' + (params[0].data === 'null' ? '-' : params[0].data) + '</br>' + params[1].seriesName + '：' + (params[1].data === 'null' ? '-' : params[1].data)
                        + '</br>' + params[2].seriesName + '：' + (params[2].data === 'null' ? '-' : params[2].data)
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '80%',
                width: '80%',
                y2: 0,
                x: '10%'
            },
            yAxis: {
                type: 'value',
                name: '数量',
				axisLabel: {
					color:"#fff"
                },
                axisLine:{
                    lineStyle:{
                        color:'#fff'
                    }
                },
            },
            xAxis: {
                type: 'category',
                data: date,
                name: '月份',
				axisLine: {
					show: true,
					lineStyle: {
						color: "#fff"
					}
				},
                axisLabel: {
                    interval: 0,
					color:"#fff"
                },
            },
            series: [
                {
                    name: '车辆',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: carData
                },
                {
                    name: '人员',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: peopleData
                },
                {
                    name: '物资',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: objectData
                }
            ]
        })
    }
    const initEcharts4 = (date, carData, objectData) => {
        echarts.init(document.getElementById('charts-4'), 'co-op-theme').setOption({
            legend: {
                data: ['车辆', '物资'],
				textStyle: {
				  color:"#fff"
				}
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    return params[0].name + '月</br>' + params[0].seriesName + '：' + (params[0].data === 'null' ? '-' : params[0].data) + '</br>' + params[1].seriesName + '：' + (params[1].data === 'null' ? '-' : params[1].data)
                },
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                containLabel: true,
                height: '80%',
                width: '80%',
                y2: 0,
                x: '10%'
            },
            yAxis: {
                type: 'value',
                name: '数量',
				axisLabel: {
					color:"#fff"
                },
                axisLine:{
                    lineStyle:{
                        color:'#fff'
                    }
                },
            },
            xAxis: {
                type: 'category',
                data: date,
                name: '月份',
				axisLine: {
					show: true,
					lineStyle: {
						color: "#fff"
					}
				},
                axisLabel: {
                    interval: 0,
					color:"#fff"
                },
            },
            series: [
                {
                    name: '车辆',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: carData
                },
                {
                    name: '物资',
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: objectData
                }
            ]
        })
    }
    return (
        <Container>
            <div className="custom-card chartsItem">
                <div className="custom-card-title">水电耗能</div>
                <div className="custom-card-content">
                    <div className="chartsContent">
                        <span className="chartsData" id="charts-1"></span>
                    </div>
                </div>
            </div>
            <div className="custom-card chartsItem">
                <div className="custom-card-title">洗消统计</div>
                <div className="custom-card-content">
                    <div className="chartsContent">
                        <span className="chartsData" id="charts-2"></span>
                    </div>
                </div>
            </div>
            <div className="custom-card chartsItem">
                <div className="custom-card-title">异常数据</div>
                <div className="custom-card-content">
                    <div className="chartsContent">
                        <span className="chartsData" id="charts-3"></span>
                    </div>
                </div>
            </div>
            <div className="custom-card chartsItem">
                <div className="custom-card-title">检验合格</div>
                <div className="custom-card-content">
                    <div className="chartsContent">
                        <span className="chartsData" id="charts-4"></span>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default ErrorPage