import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, TreeSelect, Popconfirm, Col, Form, message, Icon, Upload, Select } from 'antd'

import moment from 'moment'

import StoreSettingHttp from '../../../request/gate'

import CommonRequest from '../../../request/common-request'

import DeTable from '../../../component/DeTable';
import DeVideo from '../../../component/DeVideo'
import CommonHttp from '../../../request/common-request'

const _request = new StoreSettingHttp()
const _common = new CommonRequest()
const _commonrequest = new CommonHttp()

function Gate(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
	const [imageUrl, setImageUrl] = useState('')
    const [name, setName] = useState('')
	const [state, setState] = useState('')
	const [washList, setWashList] = useState([])
	const [deviceAddress, setDeviceAddress] = useState([])   //设备位置
	const [washName, setWashName] = useState([])
    const [equipmentNo,setEquipmentNo] = useState('')
    
    const [destoryVideo, setDestoryVideo] = useState(false)
    const tableColunm = 
        [{
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
            	
                title: '摄像位置',
                key: 'equipmentPosition',
                dataIndex: 'equipmentPosition',
                  render: (text, record, index) => (
                	deviceAddress.map((item,index1) => {
						if(record.equipmentPosition === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})
                   
                )
            },
			{
			    title: '设备号',
			    key: 'equipmentNo',
			    dataIndex: 'equipmentNo',
			},
			{
			    title: '设备名称',
			    key: 'name',
			    dataIndex: 'name',
			},
			{
			    title: '操作人',
			    key: 'createUser',
			    dataIndex: 'createUser',
			},
            {
                title: '设备类型',
                key: 'type',
                dataIndex: 'type',
                render: (text, record, index) => (
                    <span>{record.type === "1" ? '车辆摄像头' : '人物摄像头'}</span>
                )
            },
			{
			    title: '操作时间',
			    key: 'createDate',
			    dataIndex: 'createDate',
				render: (text, record, index) => (
                    <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
                )
			},
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
						<Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
							<Button style={{ marginRight: 8 }} type="danger" size="small">删除</Button>
						</Popconfirm>
                        <Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openModal.bind(this, 'edit', record)}>修改</Button>
						<Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openModal.bind(this, 'open', record)}>播放</Button>
                    </span>
                ),
            },
        ]
		
	const gateRecordColunm =
	    [
	        {
	            title: '序号',
	            key: 'number',
	            render: (text, record, index) => (
	                <span>{index + 1}</span>
	            )
	        },
			{
			    title: '开闸时间',
			    key: 'openDate',
			    dataIndex: 'openDate',
			},
			{
			    title: '车牌号',
			    key: 'carNo',
			    dataIndex: 'carNo',
			},
	    ]

     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [childRegionData, setChildRegionData] = useState([]) //猪场列表
    const [pigFarmList, setPigFarmList] = useState([]) //猪场栋舍列表
    const [expandTreeList, setExpandTreeList] = useState([])
	const [onLineType, setOnLineType] = useState('')

	// 闸机记录表格结构
	const [gateRecordDate, setGateRecordDate] = useState([]) //表格数据
    const [detailVideoUrl, setDetailVideoUrl] = useState('')
    const [tableDetailTotal, setTableDetailTotal] = useState(0)

    const deviceType = [
        {value:"1",text:"车辆摄像头"},
        {value:"2",text:"人物摄像头"}
    ] //设备类型
	
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getVideoMonitorList()
        getCarDeviceType()
        getChildRegionList()
        getPigfarm()
    }, [])
    //获取闸机列表
    const getVideoMonitorList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            equipmentNo,
			washName,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.findVideoMonitor(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
     //获取车辆摄像头关联的猪场列表
    const getChildRegionList = () => {
        let params = {}
        _commonrequest.getChildRegionList(params).then(res => {
            setChildRegionData(res)
        })
    }
    //获取人员摄像头关联的猪场栋舍列表
    	const getPigfarm = () => {
		let params = {
			regionType : 5,
			enterpriseType : 2
		}
        _commonrequest.getFramBuilddingList(params).then(res => {
            let list = []
            res.forEach(item => {
                let childList = []
                item.buildList.forEach(child => {
                    childList.push({
                        title: child.buildingName,
                        value: child.buildingId,
                        isLeaf: true
                    })
                })
                list.push({
                    title: item.pigFramName,
                    value: item.pigFramId,
                    children: childList,
                    // selectable: false,
                    // disableCheckbox: true
                })
            })
			setPigFarmList(list)
		})
	}
    
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteVideoMonitor(params).then(res => {
            message.info('删除成功！')
            getVideoMonitorList()
        })
    }
	const washSpotList = () => {
		let params = {}
		_common.getWashSpotList(params).then(res => {
			setWashList(res)
		})
	}
    //打开编辑页
    const openModal = (type, record) => {
		// 获取洗消点集合
		washSpotList()

        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type === 'edit'){ // 编辑页面
            setOnLineType(record.type)
            setTimeout(() => {
                setFieldsValue({
                    equipmentNo: record.equipmentNo,
					equipmentPosition: record.equipmentPosition,
					washSpotId : record.washSpotId,
                    id: record.id,
                    type:record.type,
                    name:record.name
                })
            }, 0)
             //根据设备类型获取关联的猪场栋舍列表
            let params = {
		        type :record.type,
		        videoId:record.id
		    }
             _request.getVideoFramList(params).then(res => {
             	let list = []
                let id
                res.forEach(item => {
                    if (!item.split) return
                    id = Number(item.split('-')[0])
                    if (!list.includes(id)) {
                        list.push(id.toString())
                    }
                })
                setExpandTreeList(list)
                debugger
                    let listValue = []
                res.forEach(item => {
                    listValue.push({
                        value: item.value,
                        label: item.label
                    })
                })
                
               if(record.type === "1"){
                	setFieldsValue({
                    	pigFramId: res.map(item => Number(item.value))
                	})
                }else{
                	setFieldsValue({
	                    pigFramId: listValue
	                })
                }
//           setFieldsValue({
//                  pigFramId: listValue
//              })
               
	        })
        }else if(type === 'open'){
            setDestoryVideo(false)
        	// 播放
        	setDetailVideoUrl(record.url)
//		    // 获取闸机开闸数据
//			let params = {
//				gateId : recordId,
//	            pageNo:pageNo,
//	            pageSize:pageSize
//			}
//			_request.gateRecordList(params).then(res => {
//				setTableDetailTotal(res.total || 0)
//			    setGateRecordDate(res.list)
//			})
        }
    }
	
	const { getFieldValue, validateFields, setFieldValue } = props.form
	
    //编辑页提交
    const editConfire = () => {
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
               let idList = getFieldValue('pigFramId').map(item => {
               
                     if (item.value) {
                        return item.value+"&"+item.label
                    }else{
                        return item
                    }
                   
                })
                if (modalType === 'add') {
                    let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
						equipmentPosition : getFieldValue('equipmentPosition'),
						washSpotId : getFieldValue('washSpotId'),
                        pigFramIds: JSON.stringify(idList),
                        type:getFieldValue('type'),
                        name:getFieldValue('name')
                    }
                    _request.addVideoMonitor(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getVideoMonitorList()
                    })
                } else {
                    let params = {
                        equipmentNo: getFieldValue('equipmentNo'),
                        equipmentPosition : getFieldValue('equipmentPosition'),
                        washSpotId : getFieldValue('washSpotId'),
                        pigFramIds: JSON.stringify(idList),
                        type:getFieldValue('type'),
                        id: getFieldValue('id'),
                         name:getFieldValue('name')
                    }
                    _request.editVideoMonitor(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getVideoMonitorList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        if (modalType === 'open') {
            setDestoryVideo(true)
        }
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //获取车辆设备位置列表
    const getCarDeviceType = () => {
        let params = {
            dictType:'CAR_WASH_TYPE',
        }
        _commonrequest.getDictList(params).then(res => {
            setDeviceAddress(res.list)
        })
    }

    //获取人物设备位置列表
    const getCarDeviceType1 = () => {
        let params = {
            dictType:'PEOPLE_WASH_TYPE',
        }
        _commonrequest.getDictList(params).then(res => {
            setDeviceAddress(res.list)
        })
    }

    const deviceTypeChange = (e) => {
            const { setFieldsValue } = props.form
            if(e === "1"){
                getCarDeviceType()
                //车辆摄像头显示猪场多选下拉框
                setOnLineType("1")
                setFieldsValue({pigFramId: []})
            }else{
                getCarDeviceType1();
                //人物摄像头显示猪栋舍树
                setOnLineType("2")
                setFieldsValue({pigFramId: null})
            }
    }

    //搜索
    const clickSearch = () => {
        getVideoMonitorList()
    }
    
    //重置
    const clickReset = () => {
		setWashName('')
		setEquipmentNo('')
        getVideoMonitorList('reset')
    }
    
	
	const { Option } = Select;
	
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10 }} onClick={openModal.bind(this, 'add')}>新增</Button>
                <Input placeholder="请输入洗消点名称" style={{ width: 200, marginRight: 10 }} value={washName} onPressEnter={clickSearch} onChange={(e) => setWashName(e.target.value)}></Input>
				<Input placeholder="请输入设备编号" style={{ width: 200, marginRight: 10 }} value={equipmentNo} onPressEnter={clickSearch} onChange={(e) => setEquipmentNo(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickReset}>重置</Button>
            </div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getVideoMonitorList('', pageNo, pageSize)}  dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增视频' : modalType === 'edit' ? '编辑' : '监控视频'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={modalType === 'open' ? false : true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    modalType === 'record' || modalType === 'open' ? null : 
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ height: 300 }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备编号：">
                                {getFieldDecorator('equipmentNo', {
                                    rules: [{ required: true, message: '请输入设备编号！' }],
                                })(
                                    <Input
                                        placeholder="请输入设备编号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                          <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备名称：">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '设备名称！' }],
                                })(
                                    <Input
                                        placeholder="设备名称"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
						<Col span={12}>
						    <Form.Item {...formItemLayout} label="所属洗消点：">
						        {getFieldDecorator('washSpotId', {
						            rules: [{ required: true, message: '请选择洗消点！' }],
						        })(
						            <Select placeholder="请选择洗消点">
						            {
										washList.map((item,index) => {
											return <Option key={item.key} value={item.id}>{item.name}</Option>
						                })
						            } 
									</Select>
						        )}
						    </Form.Item>
						</Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备类型：">
                                {getFieldDecorator('type', {
                                    rules: [{ required: true, message: '请选择设备类型！' }],
                                })(
                                    <Select placeholder="请选择设备类型"  onChange={(e) => deviceTypeChange(e)}>
                                        {
                                            deviceType.map((item,index) => {
                                                return <Option key={item.value} value={item.value}>{item.text}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备位置：">
                                {getFieldDecorator('equipmentPosition', {
                                    rules: [{ required: true, message: '请选择设备位置！' }],
                                })(
                                    <Select placeholder="请选择设备位置" >
                                        {
                                            deviceAddress.map((item,index) => {
                                                return <Option key={item.dictValue} value={item.dictValue}>{item.dictDesc}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFramId', {
									rules: [{ required: true, message: '请选择所属猪场!' }],
								})(
									
									onLineType==="1"?
									<Select mode="multiple">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
									:
									<TreeSelect
                                        style={{ width: '100%' }}
                                        treeData={pigFarmList}
                                        dropdownStyle={{ height: 300, overflow: 'auto' }}
                                        placeholder="请选择"
                                        allowClear
                                        treeCheckStrictly
                                        treeCheckable
                                        multiple
                                    >
                                    </TreeSelect>
								)}

							</Form.Item>
						</Col>
					
                    </Form>
                    : modalType === 'edit' ?
                    <Form style={{ height: 300 }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备编号：">
                                {getFieldDecorator('equipmentNo', {
                                    rules: [{ required: true, message: '请输入设备编号！' }],
                                })(
                                    <Input
                                        placeholder="请输入设备编号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                          <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备名称：">
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: '设备名称！' }],
                                })(
                                    <Input
                                        placeholder="设备名称"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="所属洗消点：">
                                {getFieldDecorator('washSpotId', {
                                    rules: [{ required: true, message: '请选择洗消点！' }],
                                })(
                                    <Select placeholder="请选择洗消点">
                                        {
                                            washList.map((item,index) => {
                                                return <Option key={item.key} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备类型：">
                                {getFieldDecorator('type', {
                                    rules: [{ required: true, message: '请选择设备类型！' }],
                                })(
                                    <Select placeholder="请选择设备类型" onChange={(e) => deviceTypeChange(e)}>
                                        {
                                            deviceType.map((item,index) => {
                                                return <Option key={item.value} value={item.value}>{item.text}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="设备位置：">
                                {getFieldDecorator('equipmentPosition', {
                                    rules: [{ required: true, message: '请选择设备位置！' }],
                                })(
                                    <Select placeholder="请选择设备位置" >
                                        {
                                            deviceAddress.map((item,index) => {
                                                return <Option key={item.dictValue} value={item.dictValue}>{item.dictDesc}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFramId')(
									
									onLineType==="1"?
									<Select mode="multiple">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
									:
									 <TreeSelect
                                        style={{ width: '100%' }}
                                        treeData={pigFarmList}
                                        dropdownStyle={{ height: 300, overflow: 'auto' }}
                                        placeholder="请选择"
                                        allowClear
                                        treeCheckStrictly
                                        treeCheckable
                                        multiple
                                    >
                                    </TreeSelect>
								)}

							</Form.Item>
						</Col>
						

                    </Form>
                    : 
                    <DeVideo destory={destoryVideo} style={{width: '100%', height: 200}} url={detailVideoUrl}></DeVideo>
					// <Input style={{ width: 600, marginRight: 10 }} value={detailVideoUrl}></Input>
			}
            </Modal>
        </Container>

    )
}

export default Form.create()(Gate)