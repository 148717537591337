import React from 'react'

import { Result } from 'antd'

function ErrorPage() {
    return (
        <Result
            status="error"
            title="404"
            subTitle="抱歉, 您访问的页面不存在"
        />
    )
}

export default ErrorPage