import React, {useState, useEffect} from 'react'

import { Container } from './style'

import { Spin } from 'antd'

import Hls from 'hls.js'

let fragCount = 0
function DeTable(props) {
    const [canPlay, setCanPlay] = useState(true)
    const [hls, setHls] = useState(null)
    const [loading, setLoading] = useState(false)
    const {url, destory} = props

    // useEffect(() => {
    //     playVideo()
    // }, [url])

    useEffect(() => {
        if (destory) {
            videoPause()
        }else{
            playVideo()
        }
    }, [destory])

    const playVideo = () => {
        if (!url || url === '') {
            return setCanPlay(false)
        }
        setLoading(true)
        let video = document.getElementById("examplevideo");
        if (Hls.isSupported()) {
            let hls = new Hls();
            hls.loadSource(url);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                console.log(1)
                video.play()
            })
            hls.on(Hls.Events.FRAG_LOADING, () => {
                console.log('--')
                fragCount++
                if (fragCount === 2) {
                    setLoading(false)
                }
            })
            hls.on(Hls.Events.ERROR, (event, data) => {
                
            })
            setHls(hls)
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = url
            video.addEventListener('canplay', () => {
                // this.showLoading = false
                // this.$emit('loadingchange', true)
                // this.showFirstPlay = false
                // this.$notify('通知内容');
                video.play()
            })
            video.addEventListener('error', () => {

            })
        }
    }
    const videoPause = () => {
        document.getElementById("examplevideo").pause()
        fragCount = 0
        let hlsObj = hls 
        hlsObj.destroy()
        hlsObj = null
        setHls(hlsObj)
    }
    return (
        <Container>
            <Spin spinning={loading} tip="视频加载中...">
                {
                    canPlay ? 
                    <video id="examplevideo" controls autoPlay muted>
                        您的设备不支持video
                    </video>
                    :
                    <div class="no-video-tip">
                        暂无视频源或错误
                    </div>
                }
            </Spin>
        </Container>
    )
}


export default DeTable