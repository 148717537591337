import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import {Form,Modal,Input,Button } from 'antd'
const FormItem = Form.Item;
class ModelSourceForm extends React.Component{
	constructor(props){
		super(props);
		this.state={
			
		}

    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
	}
	handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
    	if (err) {
        return;
      }
      values.dictId = this.props.singdata.dictId;
      this.props.setValues(values);
      this.handleCancelCode();     
      this.props.form.resetFields(); 
    });
  }
	handleCancelCode(){
		let isShow = false;
		this.props.setIsShow(isShow);
	}
	render(){
		const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { getFieldDecorator } = this.props.form;
		return (
			<Modal
			  title={this.props.modaltitle}
			  visible={this.props.isShow}
				onCancel={this.handleCancelCode.bind(this)}
				maskClosable={false}
				destroyOnClose={true}
			  footer={null}
			>
			  <Form onSubmit={(e)=>{this.handleSubmit(e)}} className="login-form">
			    <FormItem
						{...formItemLayout}
						label="大类名称"
			    >
			    	{getFieldDecorator('dictName', {
			    		initialValue:this.props.type==='add'?'':this.props.singdata.dictName,
            	rules: [{ required: true, message: '请输入大类名称!', whitespace: true }],
         		})(
			        <Input type="text"  placeholder="请输入大类名称" />
			      )}
			        
			    </FormItem>
			    <FormItem
						{...formItemLayout}
						label="字典类型"
			    >
			    	{getFieldDecorator('dictType', {
			    		initialValue:this.props.type==='add'?'':this.props.singdata.dictType,
            	rules: [{ required: true, message: '请输入字典类型!', whitespace: true }],
         		})(
			        <Input type="text"  placeholder="请输入字典类型" />
			      )}
			        
			    </FormItem>
			    <FormItem
						{...formItemLayout}
						label="小类名称"
			    >
			    	{getFieldDecorator('dictDesc', {
			    		initialValue:this.props.type==='add'?'':this.props.singdata.dictDesc,
            	rules: [{ required: true, message: '请输入小类名称!', whitespace: true }],
         		})(
			        <Input type="text"  placeholder="请输入小类名称" />
			      )}
			        
			    </FormItem>
			    <FormItem
						{...formItemLayout}
						label="字典值"
			    >
			    	{getFieldDecorator('dictValue', {
			    		initialValue:this.props.type==='add'?'':this.props.singdata.dictValue,
            	rules: [{ required: true, message: '请输入字典值!', whitespace: true }],
         		})(
			        <Input type="text"  placeholder="请输入字典值" />
			      )}
			        
			    </FormItem>
			    <FormItem
						{...formItemLayout}
						label="字典索引"
			    >
			    	{getFieldDecorator('dictIndex', {
			    		initialValue:this.props.type==='add'?1:this.props.singdata.dictIndex*1,
            	rules: [{ required: true, message: '请输入字典索引!' }],
         		})(
			        <Input type="number"  placeholder="请输入字典索引" />
			      )}
			        
			    </FormItem>
			    
			    <FormItem>
			      <div style={{textAlign:'center'}}><Button type="primary" htmlType="submit" style={{width:'30%'}}>
			        提交
			      </Button></div>
			    </FormItem>
			  </Form>
			</Modal>
		)
	}
}
const ModelSource = Form.create()(ModelSourceForm);
export default ModelSource;


