import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon, Upload, Select } from 'antd'
import { DatePicker } from 'antd';

import moment from 'moment'

import StoreSettingHttp from '../../../request/peopleWash'

import DeTable from '../../../component/DeTable';

const _request = new StoreSettingHttp()
const { MonthPicker, RangePicker } = DatePicker;

function PeopleWash(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [name, setName] = useState('')
	const [washName, setWashName] = useState('')
	const [state, setState] = useState('')
	const [imagePath, setImagePath] = useState(false) //图片查看
	const [imgFace, setImgFace] = useState('')
	const [imagPath, setImagPath] = useState('')
	const [extractWork, setExtractWork] = useState('')
    const [startTime, setStartTime] = useState('')
	const [endTime, setEndTime] = useState('')
	const qrStyle ={
		color:'white',
		margin: '10px auto',
		width: '40%'
	}
	const centerStyle={
		width: '100%',
		textAlign: 'center'
	}
    const tableColunm = 
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
			{
			    title: '洗消点',
			    dataIndex: 'washSpotName',
			    key: 'washSpotName',
			},
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
			{
			    title: '流程',
			    dataIndex: 'workflowName',
			    key: 'workflowName',
			},
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
            {
                title: '视频位置',
                key: 'videoName',
                dataIndex: 'videoName',
            },
			{
			    title: '操作时间',
			    key: 'createDate',
			    dataIndex: 'createDate',
				render: (text, record, index) => (
				    <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>
				)
			},
			{
			    title: '岗位',
			    key: 'post',
			    dataIndex: 'post',
			},
			{
				title: '人脸图片',
				key: 'imgPath',
				dataIndex: 'imgPath',
				render: (imgPath, record) => (
					<Button type="primary" size="small" onClick={imagesPath.bind(this, imgPath, record)}>查看图片</Button>
				)
			},
		]
		
	//查看图片
	const imagesPath = (imgPath,record) => {
		setImgFace(record)
		setImagePath(true)
		setImagPath(imgPath)
		const extractWork = record.workflowName
		setExtractWork(extractWork.substring(0,4))
	}
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getWashList()
    }, [])
    //获取员工列表
    const getWashList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            name,
			washName,
            startTime:startTime,
            endTime: endTime,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getWashList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }

    //搜索
    const clickSearch = () => {
        getWashList()
    }
	
    //重置
    const clickReset = () => {
		setName('')
		setWashName('')
		setStartTime('')
		setEndTime('')
        getWashList('reset')
    }
    
    
    //日期控件搜索
    function range(start, end) {
	  const result = [];
	  for (let i = start; i < end; i++) {
	    result.push(i);
	  }
	  return result;
	}
	
	function disabledDate(current) {
	  // Can not select days before today and today
	  return current && current < moment().endOf('day');
	}

    function disabledDateTime() {
	  return {
	    disabledHours: () => range(0, 24).splice(4, 20),
	    disabledMinutes: () => range(30, 60),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
	function disabledRangeTime(_, type) {
	  if (type === 'start') {
	    return {
	      disabledHours: () => range(0, 60).splice(4, 20),
	      disabledMinutes: () => range(30, 60),
	      disabledSeconds: () => [55, 56],
	    };
	  }
	  return {
	    disabledHours: () => range(0, 60).splice(20, 4),
	    disabledMinutes: () => range(0, 31),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
    function rangeTimeChange(date){
    	if(date){
    		if(date[0]!=undefined){
    			setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setStartTime('');
    		}
    		if(date[1]!=undefined){
    			setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setEndTime('');
    		}
    	}
    }
	
	const imageEdit = () =>{
        setImagePath(false)
    }

    return (
        <Container>
            <div className="top">
			    <Input placeholder="请输入洗消点名称" style={{ width: 200, marginRight: 10 }} value={washName} onPressEnter={clickSearch} onChange={(e) => setWashName(e.target.value)}></Input>
                <Input placeholder="请输入姓名" style={{ width: 200, marginRight: 10 }} value={name} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <RangePicker
                	ranges={{
				        Today: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				        'This Month': [moment().startOf('month'), moment().endOf('month')],
				      }}
	                value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
				    /*disabledTime={disabledRangeTime}*/
				    onChange={rangeTimeChange}
				    showTime={{
				    	hideDisabledOptions: true,
				        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				    }}
				    format="YYYY-MM-DD HH:mm:ss"
				/>
	                
               	&nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickReset}>重置</Button>
            </div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getWashList('', pageNo, pageSize)}  dataSource={tableData} />
            </div>
			<Modal
                title={'人脸图片'}
                visible={imagePath}
                centered={true}
                width={'50%'}
                onCancel={imageEdit}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                
                {
                    imagPath ?
					<div style={qrStyle}>
						<div style={centerStyle}><img src={imagPath} alt="" /></div>
						<div style={{ marginTop: 10 }}>
							<p>洗消点：{ imgFace.washSpotName ? imgFace.washSpotName : '无' }</p>
							<p>姓名：{ imgFace.name ? imgFace.name : '无' }</p>
							<p>位置：{ imgFace.videoName ? (imgFace.workflowId == -1 ? extractWork + ' ' + imgFace.videoName : imgFace.videoName) : imgFace.workflowName }</p>
							<p>操作时间：{ imgFace.createDate ? moment(imgFace.createDate).format("YYYY-MM-DD HH:mm:ss") : '无' }</p>
							<p>岗位：{ imgFace.post ? imgFace.post : '无' }</p>
						</div>
					</div>
					:
                    <div style={ qrStyle }>暂无图片</div>
				}
            </Modal>
        </Container>

    )
}

export default Form.create()(PeopleWash)