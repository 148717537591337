import React from 'react'
import PureRenderMixin from 'react-addons-pure-render-mixin'
import {Form,Modal,Input,Button,Radio } from 'antd'

const RadioGroup = Radio.Group;
const FormItem = Form.Item;

class ModelSourceForm extends React.Component{
	constructor(props){
		super(props);
		this.state={
			organizechoosedata:this.props.organizechoosedata
		}

    this.shouldComponentUpdate = PureRenderMixin.shouldComponentUpdate.bind(this);
	}
	handleSubmit(e){
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
    	if (err) {
        return;
      }
      this.props.setValues(values);
      this.handleCancelCode();     
      this.props.form.resetFields(); 
    });
  }
	handleCancelCode(){
		let isShow = false;
		this.props.setIsShow(isShow);
	}
	checkName(rule, value, callback){
	    if((/[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/.test(value))){ 
	        callback("用户名不能有特殊字符!");  
	    }  else {
	      callback();
	    }
	 }
	render(){
		const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { getFieldDecorator } = this.props.form;
		return (
			<Modal
			  title={this.props.modaltitle}
			  visible={this.props.isShow}
				onCancel={this.handleCancelCode.bind(this)}
				maskClosable={false}
				destroyOnClose={true}
			  footer={null}
			>
			  <Form onSubmit={(e)=>{this.handleSubmit(e)}} className="login-form">
			    <FormItem
						{...formItemLayout}
						label="角色名称"
			    >
			    	{getFieldDecorator('roleName', {
			    		initialValue:this.props.type==='add'?'':this.props.organizechoosedata.roleName,
            	rules: [{ required: true, message: '请输入角色名称!', whitespace: true },{ validator: this.checkName.bind(this)}],
         		})(
			        <Input type="text" maxLength={8}  placeholder="请输入角色名称" />
			      )}
			        
			    </FormItem>
					{
						!this.props.organizechoosedata
						?''
						:<FormItem
							{...formItemLayout}
							label="是否有效"
				    >
				    	{getFieldDecorator('isValid', { 
				    		initialValue:this.props.organizechoosedata.isValid
	         		})(
				        <RadioGroup >
					        <Radio value={1}>有效</Radio>
					        <Radio value={0}>无效</Radio>
					      </RadioGroup>
				      )}
				        
				    </FormItem>
					}
			    
			    <FormItem>
			      <div style={{textAlign:'center'}}><Button type="primary" htmlType="submit" style={{width:'30%'}}>
			        提交
			      </Button></div>
			    </FormItem>
			  </Form>
			</Modal>
		)
	}
}
const ModelSource = Form.create()(ModelSourceForm);
export default ModelSource;


