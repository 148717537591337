import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    .top{
        flex: 10;
        display: flex;
        .chartsItem{
            margin: 8px;
            height: calc(100% - 16px);
            &.todyWash{
                flex: 2;
            }
            &.timeSum{
                flex: 1;
            }
            .custom-card-content{
                height: 100%;
                display: flex;
                flex-direction: column;
                .todayContent{
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .todayItem{
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        img{
                            width: 48%;
                            margin-right: 12px;
                        }
                        p{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            span:first-child{
                                color: #999;
                                font-size: 16px;
                            }
                            span:last-child{
                                font-weight: bold;
                                font-size: 24px;
                                color: #333;
                            }
                        }
                    }
                }
                .sumContainer {
                    flex: 1;
                    overflow: hidden;
                    height: 0;
                    .sumContent{
                        height: 100%;
                        width: calc(100% + 20px);
                        padding-right: 20px;
                        box-sizing: border-box;
                        overflow-y: scroll;
                        .pContainer{
                            display: flex;
                            align-items: center;
                            p{
                                margin-bottom: 8px;
                                width: 0;
                                flex: 1;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }
        }
    }
    .bottom{
        flex: 13;
        display: flex;
        .chartsItem{
            margin: 8px;
            width: calc(100% - 16px);
            height: calc(100% - 16px);
            .custom-card-content{
                display: flex;
                justify-content: space-between;
                height: calc(100% - 60px);
                .chartsContent{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .chartsData{
                        font-size: 32px;
                        color: #333333;
                        width: 100%;
                        height: 100%;
                        padding: 8px;
                    }
                }
            }
        }
    }
`