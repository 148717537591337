import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Select, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import TempCarInfoHttp from '../../../request/car-request'
import CommonHttp from '../../../request/common-request'


const Option = Select.Option;
const _request = new TempCarInfoHttp()
const _commonrequest = new CommonHttp()

function TempCarInfo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [carNo, setCarNo] = useState('')
    const [driver, setDriver] = useState('')
    
    const tableColunm = 
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '车牌号',
                dataIndex: 'carNo',
                key: 'carNo',
            },
            {
                title: '车辆类型',
                key: 'carType',
                dataIndex: 'carType',
                render: (text, record, index) => (
                	carTypeData.map((item,index1) => {
						if(record.carType === item.dictValue) {
							return  <span>{item.dictDesc}</span>
						}
					})
                   
                )
            },
            {
                title: '驾驶人',
                dataIndex: 'driver',
                key: 'driver',
            },
//          {
//              title: '所属猪场',
//              key: 'pigFramName',
//              dataIndex: 'pigFramName',
//          },
            {
                title: '手机号',
                key: 'phone',
                dataIndex: 'phone',
            },
            {
                title: '登记人',
                key: 'registrant',
                dataIndex: 'registrant',
            },
            {
                title: '操作时间',
                dataIndex: 'createDate',
                key: 'createDate',
				render: (text, record, index) => {
					return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <Button style={{ marginRight: 8 }} type="primary" size="small" onClick={openModal.bind(this, 'edit', record)}>编辑</Button>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteRow.bind(this, record.id)}>
                            <Button type="danger" size="small">删除</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
     //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const [carTypeData, setCarTypeData] = useState([]) //车辆类型列表
    const [childRegionData, setChildRegionData] = useState([]) //猪场列表
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getTempCarInfoList()
        getDictList()
        getChildRegionList()
    }, [])
    //获取列表
    const getTempCarInfoList = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            carNo:carNo,
            driver:driver,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.getTempCarInfoList(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }
    
     //获取车辆类型列表
    const getDictList = () => {
        let params = {
           dictType:'CAR_TYPE'
        } 
        _commonrequest.getDictList(params).then(res => {
            setCarTypeData(res.list)
        })
    }
    //获取猪场列表
    const getChildRegionList = () => {
        let params = {}
        _commonrequest.getChildRegionList(params).then(res => {
            setChildRegionData(res)
        })
    }
    //删除最外的表格行
    const deleteRow = (id) => {
        let params = {
            id
        }
        _request.deleteTempCarInfo(params).then(res => {
            message.info('删除成功！')
            getTempCarInfoList()
        })
    }
    //打开编辑页
    const openModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditVisible(true)
        if(type !== 'add'){
        	//获取关联的猪场列表
            let params = {
            	tempCarId:record.id
            }
	        _request.getTempCarFramList(params).then(res => {
	                setFieldsValue({
	                    pigFramId:res.map(item => Number(item)),//.map(Number)
	                })
	        })
        	
            setTimeout(() => {
                setFieldsValue({
                    carNo: record.carNo,
                    carType: record.carType,
                    driver: record.driver,
                    phone: record.phone,
                    //pigFramId: Number(record.pigFramId),
                    remark:record.remark,
                    id: record.id
                })
            }, 0)
        }
    }
    //编辑页提交
    const editConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            if (!err) {
                setConfirmLoading(true)
               //let regionName = childRegionData.filter(item => item.regionId === getFieldValue('pigFramId'))[0].regionName
               //let newData = getFieldValue('pigFramId').map(item => item.toString()).join('-')
               if (modalType === 'add') {
                    let params = {
                        carNo: getFieldValue('carNo'),
                        carType: getFieldValue('carType'),
                        driver: getFieldValue('driver'),
                        phone: getFieldValue('phone'),
                        pigFramIds: JSON.stringify(getFieldValue('pigFramId')),
                        //pigFramId: newData,
                        //pigFramName: regionName,
                        remark: getFieldValue('remark')
                    }
                    _request.addTempCarInfo(params).then(res => {
                        setConfirmLoading(false)
                        message.info('新增成功！')
                        setEditVisible(false)
                        getTempCarInfoList()
                    })
                } else {
                    let params = {
                        carNo: getFieldValue('carNo'),
                        carType: getFieldValue('carType'),
                        driver: getFieldValue('driver'),
                        phone: getFieldValue('phone'),
                        pigFramIds: JSON.stringify(getFieldValue('pigFramId')),
                        //pigFramId: newData,
                        //pigFramName: regionName,
                        remark: getFieldValue('remark'),
                        id: getFieldValue('id')
                    }
                    _request.updateTempCarInfo(params).then(res => {
                        setConfirmLoading(false)
                        message.info('修改成功！')
                        setEditVisible(false)
                        getTempCarInfoList()
                    })
                }
            }
        })
    }
    //编辑页取消
    const cancelEdit = () => {
        setEditVisible(false)
        setModalType('')
        setConfirmLoading(false)
    }

    //搜索
    const clickSearch = () => {
        getTempCarInfoList()
    }
    //重置
    const clickReset = () => {
		setDriver('')
		setCarNo('')
        getTempCarInfoList('reset')
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" style={{ marginRight: 10 }} onClick={openModal.bind(this, 'add')}>新增</Button>
                <Input placeholder="请输入车牌号" style={{ width: 200, marginRight: 10 }} value={carNo} onPressEnter={clickSearch} onChange={(e) => setCarNo(e.target.value)}></Input>
                <Input placeholder="请输入驾驶员" style={{ width: 200, marginRight: 10 }} value={driver} onPressEnter={clickSearch} onChange={(e) => setDriver(e.target.value)}></Input>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>搜索</Button>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickReset}>重置</Button>
            </div>
            <div className="bottom"> 
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getTempCarInfoList('', pageNo, pageSize)}  dataSource={tableData} />
            </div>
            <Modal
                title={modalType === 'add' ? '新增车辆' : '编辑'}
                visible={editVisible}
                onCancel={cancelEdit}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='editClass'
                footer={
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editConfire}>提交</Button>
                    </div>
                }
            >
                {
                    modalType === 'add' ?
                    <Form style={{ display: 'inline-block' }}>
                        <Form.Item>
                            <span></span>
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="车牌号：">
                                {getFieldDecorator('carNo', {
                                    rules: [{ required: true, message: '请输入车牌号！',pattern:new RegExp(/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入车牌号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                         <Col span={12}>
							<Form.Item {...formItemLayout} label="车辆类型：">
								{getFieldDecorator('carType', {
									rules: [{ required: true, message: '请选择车辆类型!' }],
								})(
									<Select >
										{
							  				carTypeData.map((item,index) => {
								  				return (
								  					<Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                        
                         <Col span={12}>
                            <Form.Item {...formItemLayout} label="驾驶人：">
                                {getFieldDecorator('driver', {
                                    rules: [{ required: true, message: '请输入驾驶人！' }],
                                })(
                                    <Input
                                        placeholder="请输入驾驶人"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="手机号：">
                                {getFieldDecorator('phone', {
                                    rules: [{ required: true, message: '请输入手机号！',pattern:new RegExp(/^1[3456789]\d{9}$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入手机号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFramId', {
									rules: [{ required: true, message: '请选择所属猪场!' }],
								})(
									<Select  mode="multiple">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                         <Col span={12}>
                            <Form.Item {...formItemLayout} label="事项说明：">
                                {getFieldDecorator('remark')(
                                    <Input
                                        placeholder="请输入事项说明"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                    :
                    <Form style={{ display: 'inline-block' }}>
                        <Form.Item>
                            {getFieldDecorator('id')(
                                <span></span>
                            )}
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="车牌号：">
                                {getFieldDecorator('carNo', {
                                    rules: [{ required: true, message: '请输入车牌号！',pattern:new RegExp(/^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入车牌号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                         <Col span={12}>
							<Form.Item {...formItemLayout} label="车辆类型：">
								{getFieldDecorator('carType', {
									rules: [{ required: true, message: '请选择车辆类型!' }],
								})(
									<Select >
										{
							  				carTypeData.map((item,index) => {
								  				return (
								  					<Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="驾驶人：">
                                {getFieldDecorator('driver', {
                                    rules: [{ required: true, message: '请输入驾驶人！' }],
                                })(
                                    <Input
                                        placeholder="请输入驾驶人"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item {...formItemLayout} label="手机号：">
                                {getFieldDecorator('phone', {
                                    rules: [{ required: true, message: '请输入手机号！',pattern:new RegExp(/^1[3456789]\d{9}$/, "g") }],
                                })(
                                    <Input
                                        placeholder="请输入手机号"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
							<Form.Item {...formItemLayout} label="所属猪场：">
								{getFieldDecorator('pigFramId', {
									rules: [{ required: true, message: '请选择所属猪场!' }],
								})(
									<Select  mode="multiple">
										{
							  				childRegionData.map((item,index) => {
								  				return (
								  					<Option value={item.regionId} key={item.regionId} >{item.regionName}</Option>
								  				)
							  				})
										}
									</Select>
								)}

							</Form.Item>
						</Col>
                         <Col span={12}>
                            <Form.Item {...formItemLayout} label="事项说明：">
                                {getFieldDecorator('remark')(
                                    <Input
                                        placeholder="请输入事项说明"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                }
            </Modal>
        </Container>

    )
}

export default Form.create()(TempCarInfo)