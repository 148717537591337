import React, { useState, useEffect } from 'react'
import { Container } from './style'

import FlowTree from '../../../component/FlowTree'

import { Button, Select, message, Form, Input, Col, Popconfirm, Modal} from 'antd'

import DeTable from '../../../component/DeTable'
import moment from 'moment'

import DecontaminateHttp from '../../../request/decontaminate-request'
import CommonHttp from '../../../request/common-request'
const _request = new DecontaminateHttp()
const _commonrequest = new CommonHttp()
const { Option } = Select
const { confirm } = Modal

function WorkFlowPic(props){
    const [saveLoading, setSaveLoading] = useState(false)
    const [washSpotId, setWashSpotId] = useState()
    const [washSpotData, setWashSpotData] = useState([]) //洗消点列表
    const [isChangeFlow, setIsChangeFlow] = useState(false)

    const [modalType, setModalType] = useState('') //新增or编辑
    const [workFlowId, setWorkFlowId] = useState('') //流程id
    const [editCheckVisible, setEditCheckVisible] = useState(false) //检测项编辑项
    const [detailVisible, setDetailVisible] = useState(false) //检测项列表
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [itemName, setItemName] = useState('')//检测项查询条件
    const [tableDetailData, setTableDetailData] = useState([]) //检测项表格数据

    const [carData, setCarData] = useState([])
    const [peopleData, setPeopleData] = useState([])
    const [objectData, setObjectData] = useState([])

    const tableDetailColunm =
        [
            //          {
            //              title: 'ID',
            //              dataIndex: 'id',
            //              key: 'id',
            //          },
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '检测项',
                dataIndex: 'item',
                key: 'item',
            },
            {
                title: '创建人',
                key: 'createUser',
                dataIndex: 'createUser',
            },
            {
                title: '操作日期',
                dataIndex: 'createDate',
                key: 'createDate',
                render: (text, record, index) => {
                    return <span>{moment(record.createDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
                }
            },
            {
                title: '操作',
                key: 'action',
                render: (text, record, index) => (
                    <span>
                        <Popconfirm title="确认删除？" okText="删除" okType="danger" cancelText="取消" onConfirm={deleteCheckSetRow.bind(this, record.id)}>
                            <Button type="danger" size="small">删除</Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
    
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form

    useEffect(() => {
        getWashSpotList()
    }, [])
    //获取列表
    const getWorkFlowList = (id) => {
        let params = {
            washSpotId: id
        }
        _request.getWorkFlowList(params).then(res => {
            let list = res.list
            let carList = []
            let peopleList = []
            let objectList = []
            if (list && list.length !== 0) {
                list.forEach(item => {
                    if (item.type === '1') {
                        carList.push(item)
                    }else if(item.type === '2'){
                        peopleList.push(item)
                    }else if(item.type === '3'){
                        objectList.push(item)
                    }
                })
            }
            setCarData(carList)
            setPeopleData(peopleList)
            setObjectData(objectList)
        })
    }

    //获取当前登录用户下的洗消点列表
    const getWashSpotList = () => {
        _commonrequest.getWashSpotList().then(res => {
            setWashSpotData(res)
            if(res && res.length !== 0){
                setWashSpotId(res[0].id)
                getWorkFlowList(res[0].id)
            }
        })
    }

    const chooseWashSpot = (e) => {
        if (isChangeFlow) {
            confirm({
                title: '是否保存当前洗消点流程设置？',
                content: '当前洗消点流程设置未保存，离开将导致该洗消点数据丢失！',
                okText: '立即保存',
                cancelText: '暂不保存',
                onOk() {
                    savaData()
                },
                onCancel() {
                    setWashSpotId(e)
                    getWorkFlowList(e)
                    setIsChangeFlow(false)
                }
            })
        }else{
            setWashSpotId(e)
            getWorkFlowList(e)
        }
    }

    const savaData = () => {
        if (isRepeat(carData) || isRepeat(peopleData) || isRepeat(objectData)) {
            return message.error('存在重复流程！')
        }
        setSaveLoading(true)
        function carP() {
            return new Promise((resolve, reject) => {
                _request.saveWorkFlowPic({
                    type: '1',
                    washSpotId,
                    data: JSON.stringify(carData)
                }).then(() => {
                    resolve()
                }).catch(() => {
                    setSaveLoading(false)
                    reject()
                })
            })
        }
        function peopleP(){
            return new Promise((resolve, reject) => {
                _request.saveWorkFlowPic({
                    type: '2',
                    washSpotId,
                    data: JSON.stringify(peopleData)
                }).then(() => {
                    resolve()
                }).catch(() => {
                    setSaveLoading(false)
                    reject()
                })
            })
        }
        function objectP(){
            return new Promise((resolve, reject) => {
                _request.saveWorkFlowPic({
                    type: '3',
                    washSpotId,
                    data: JSON.stringify(objectData)
                }).then(() => {
                    resolve()
                }).catch(() => {
                    setSaveLoading(false)
                    reject()
                })
            })
        }
        carP()
        .then(() => peopleP())
        .then(() => objectP())
        .then(() => {
            message.info('保存设置成功！')
            getWorkFlowList(washSpotId)
            setSaveLoading(false)
            setIsChangeFlow(false)
        })
    }

    //检查流程是否重复
    const isRepeat = (data) => {
        let list = []
        data.forEach(item => {
            list.push(item.workflowName)
        })
        return [...new Set(list)].length !== data.length
    }

    //检测项
    //获取检测项列表
    const getCheckSetList = (id) => {
        let params = {
            workFlowId: id || workFlowId,
            item: itemName
        }
        if (id === 'reset') {
            params = { 
            	workFlowId:workFlowId
            }
        }
        _request.getCheckSetList(params).then(res => {
            let list = []
            list = res.list.map(item => {
                return {
                    ...item,
                    key: item.key
                }
            })
            setTableDetailData(list)
        })
    }
    const openCheckModal = (type, record) => {
        const { setFieldsValue } = props.form
        setModalType(type)
        setEditCheckVisible(true)
        setTimeout(() => {
            setFieldsValue({
                item: record.item,
                id: record.id
            })
        }, 0)
    }
    const cancelDetail = () => {
        setDetailVisible(false)
        setConfirmLoading(false)
    }
    const cancelCheckEdit = () => {
        setEditCheckVisible(false)
        setConfirmLoading(false)
    }
    const clickCheckSearch = () => {
        getCheckSetList()
    }
    //重置
    const clickCheckReset = () => {
    	setItemName('')
        getCheckSetList('reset')
    }
    
    //删除检测项表格行
    const deleteCheckSetRow = (id) => {
        let params = {
            id
        }
        _request.deleteCheckSet(params).then(res => {
            message.info('删除成功！')
            getCheckSetList()
        })
    }
    //新增检测项
    const editCheckConfire = () => {
        const { getFieldValue, validateFields } = props.form
        validateFields((err, value) => {
            setConfirmLoading(true)
            if (modalType === 'addCheck') {
                let params = {
                    item: getFieldValue('item'),
                    workFlowId: workFlowId
                }
                _request.addCheckSet(params).then(res => {
                    setConfirmLoading(false)
                    message.info('新增成功！')
                    setEditCheckVisible(false)
                    getCheckSetList()
                })
            }
        })
    }
    const openCheckItem = (id) => {
        if (!id || id === '') {
            return message.warning('请先保存流程再设置检测项！')
        }
        setDetailVisible(true)
        setWorkFlowId(id)
        getCheckSetList(id)
    }
    return (
        <Container>
            <div className="top">
                <Button type="primary" loading={saveLoading} style={{ marginRight: 10 }} disabled={!isChangeFlow} onClick={savaData}>保存设置</Button>
                <Select style={{ width: 200, marginRight: 10 }} placeholder="请选择洗消点" value={washSpotId} onChange={chooseWashSpot}>
                    {
                        washSpotData.map((item, index) => {
                            return (
                                <Option value={item.id} key={item.id} >{item.name}</Option>
                            )
                        })
                    }
                </Select>
            </div>
            <div className="bottom">
                <FlowTree title="1" onChange={val => {setCarData(val); setIsChangeFlow(true)}} list={carData} openCheckItem={openCheckItem}></FlowTree>
                <FlowTree title="2" onChange={val => {setPeopleData(val); setIsChangeFlow(true)}} list={peopleData} openCheckItem={openCheckItem}></FlowTree>
                <FlowTree title="3" onChange={val => {setObjectData(val); setIsChangeFlow(true)}} list={objectData}></FlowTree>
            </div>
            <Modal
                title={'流程检测项'}
                visible={detailVisible}
                onCancel={cancelDetail}
                maskClosable={false}
                destroyOnClose={true}
                centered={true}
                width={'50%'}
                className='detailClass'
                footer={null}
            >
                <div style={{marginBottom: 10}}>
                    <Button type="primary" style={{ marginRight: 10 }} onClick={openCheckModal.bind(this, 'addCheck')}>新增</Button>
                    <Input placeholder="请输入检测项" style={{ width: 200, marginRight: 10 }} value={itemName} onPressEnter={clickCheckSearch} onChange={(e) => setItemName(e.target.value)}></Input>
                    <Button type="primary" style={{ marginRight: 10 }} onClick={clickCheckSearch}>搜索</Button>
                	<Button type="primary" style={{ marginRight: 10 }} onClick={clickCheckReset}>重置</Button>
                </div>
                <div>
                    <DeTable columns={tableDetailColunm} pagination={{ showSizeChanger: true, showTotal: (total) => `共 ${total} 条` }} dataSource={tableDetailData} />
                </div>

                <Modal
                    title={modalType === 'addCheck' ? '新增检测项' : '编辑检测项'}
                    visible={editCheckVisible}
                    onCancel={cancelCheckEdit}
                    maskClosable={false}
                    destroyOnClose={true}
                    centered={true}
                    width={'50%'}
                    className='editCheckClass'
                    footer={
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <Button type="primary" style={{ width: 200 }} loading={confirmLoading} onClick={editCheckConfire}>提交</Button>
                        </div>
                    }
                >
                    {
                        <Form style={{ height: 100 }}>
                            <Form.Item>
                                <span></span>
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item {...formItemLayout} label="检测项名称：">
                                    {getFieldDecorator('item', {
                                        rules: [{ required: true, message: '请输入检测项名称！' }],
                                    })(
                                        <Input
                                            placeholder="请输入检测项名称"
                                        />,
                                    )}
                                </Form.Item>
                            </Col>
                        </Form>
                    }
                </Modal>
            </Modal>
        </Container>
    )
}

export default Form.create()(WorkFlowPic)