import React, {useState, useEffect} from 'react'

import { Container } from './style'

import { Steps, Icon, InputNumber, Select, Button } from 'antd'

import CommonHttp from '../../request/common-request'
const _commonrequest = new CommonHttp()

const { Option } = Select

const { Step } = Steps

function FlowTree(props) {
	
    const { title, list, onChange, openCheckItem } = props
    const [flowList, setFlowList] = useState([])
    const [flowTypeData, setFlowTypeData] = useState([]) //流程类型列表
    useEffect(() => {
        setFlowList(list)
        if (flowTypeData.length === 0) {
            getDictList()
        }
    }, [list])
    const handleTimeChange = (index, val) => {
        let list = [...flowList]
        list[index].workflowTime = val
        setFlowList(list)
        onChange(list)
    }
    const handleTempChange = (index, val) => {
        let list = [...flowList]
        list[index].workflowTemperature = val
        setFlowList(list)
        onChange(list)
    }
    const chooseFlowType = (index, val) => {
        let list = [...flowList]
        list[index].workflowName = val
        setFlowList(list)
        onChange(list)
    }
    //获取流程类型列表
    const getDictList = () => {
        let params = {
            dictType: title === '1' ? 'CAR_WORKFLOW_TYPE' : title === '2' ? 'PEOPLE_WORKFLOW_TYPE' : title === '3' ? 'GOOD_WORKFLOW_TYPE' : '',
            isDefault: '1'//如果IsDefault不是空的，用来查询洗消类型不为0病毒检测的数据
        }
        _commonrequest.getDictList(params).then(res => {
            setFlowTypeData(res.list)
			console.log(res.list)
        })
    }
    //增加流程节点
    const addFlow = () => {
        let list = [...flowList]
        list.push({
            workflowName: '2',
            workflowTime: '20'
        })
        setFlowList(list)
        onChange(list)
    }
    //删除节点
    const deleteFlow = (index) => {
        let list = [...flowList]
        list.splice(index, 1)
        setFlowList(list)
        onChange(list)
    }
    return (
        <Container className="flowPic">
            <div className="title">{title === '1' ? '车辆' : title === '2' ? '人员' : title === '3' ? '物料' : ''}</div>
            <Steps direction="vertical">
                {
                    flowList.map((item, index) => {
                        return <Step status="process" key={index} icon={<div className="stepIcon"><span>{index + 1}</span><Icon className="close" onClick={deleteFlow.bind(this, index)} type="close" /></div>} title={
                            <Select style={{ width: 90 }} size="small" placeholder="请选择流程" value={item.workflowName} onChange={chooseFlowType.bind(this, index)}>
                                {
                                    flowTypeData.map((item, index) => {
                                        return (
                                            <Option value={item.dictValue} key={item.dictValue} >{item.dictDesc}</Option>
                                        )
                                    })
                                }
                            </Select>
                        } description={
                            <React.Fragment>
                                <InputNumber style={{width: 60, marginRight: 2}} defaultValue={0} value={item.workflowTime} size="small" onChange={handleTimeChange.bind(this, index)}></InputNumber><span>分钟</span>
                                {
                                    item.workflowName === '8' ? 
                                    <React.Fragment>
                                        <br/>
                                        <InputNumber style={{ width: 60, marginRight: 2, marginTop: 3 }} defaultValue={0} value={item.workflowTemperature} size="small" onChange={handleTempChange.bind(this, index)}></InputNumber><span>℃</span>
                                    </React.Fragment>
                                    : ''
                                }
                                <br/>
                                {
                                    (item.workflowName === '2' || item.workflowName === '8') && title === '1' ? 
                                    <Button size="small" style={{marginTop: 3, width: 90}} type={item.id ? 'primary' : 'dashed'} onClick={openCheckItem.bind(this, item.id)}>检测项设置</Button>
                                    : (item.workflowName === '6'  && title === '2' ? 
                                    <Button size="small" style={{marginTop: 3, width: 90}} type={item.id ? 'primary' : 'dashed'} onClick={openCheckItem.bind(this, item.id)}>检测项设置</Button>
									: '')
                                }
								
                            </React.Fragment>
                        }/>
                    })
                }
                <Step status="wait" title={flowList.length === 0 ? '新增流程' : ''} key={-1} icon={<Icon className="addFlow" onClick={addFlow} type="plus-circle" />} />
            </Steps>
        </Container>
    )
}


export default FlowTree