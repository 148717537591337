import request from '../https'

//公共请求
class CommonRequest{
     
    //字典表：获取类型列表=========================
    //调用的地方需要传dictType参数
    getDictList(params){
        return request({
            url: 'client/dict/list',
            method: 'post',
            params
        })
    }
    
    //获取当前用户下的猪场列表=========================
    getChildRegionList(params){
        return request({
            url: 'client/area/getChildrenRegionInfo',
            method: 'post',
            data:{
	            regionType:5,
	            enterpriseType:2
            }
        })
    }
        getChildRegionListAll(params){
        return request({
            url: 'client/area/getChildrenRegionInfo',
            method: 'post',
            data:{
	            regionType:5 
            }
        })
    }
    
     //获取当前登录用户下的洗消点列表=========================
    getWashSpotList(params){
        return request({
            url: 'client/common/washSpotListByFramId',
            method: 'post',
            params
        })
    }
    
     //获取猪场关联的栋舍列表=========================
    getBuilddingList(params){
        return request({
            url: 'client/pigFram/getBuilddingList',
            method: 'post',
            params
        })
    }

    //获取回显的栋舍列表
    getPreBuildding(params){
        return request({
            url: 'client/people/getPeopleFramList',
            method: 'post',
            params
        })
    }
    
     //获取回显的栋舍列表
    getTempPreBuildding(params){
        return request({
            url: 'client/visitor/getPeopleTempFramList',
            method: 'post',
            params
        })
    }
     //获取猪场栋舍列表
    getFramBuilddingList(params){
        return request({
            url: 'client/pigFram/getFramBuilddingList',
            method: 'post',
            params
        })
    }
    
    
}

export default CommonRequest