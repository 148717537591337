import * as actionTypes from './actionTypes'
const defaultState = {
    subScreen: [{
        title: '首页',
        id: -1,
        active: true
    }],
    // subScreen: [{
    //     title: '调试',
    //     id: 32,
    //     active: true
    // }],
    userInfo: {}, //用户信息
    powerButtonList: [], //可配置的按钮组
    specialSubScreen: {} //从其他页面进入特定的tab页
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_SCREEN:
            return {
                ...state,
                subScreen: action.data
            }
        case actionTypes.CHANGE_USERINFO:
            return {
                ...state,
                userInfo: action.data
            }
        case actionTypes.CHANGE_POWERBUTTON_LIST:
            return {
                ...state,
                powerButtonList: action.data
            }
        case actionTypes.CHANGE_SPECIAL_SCREEN:
            return {
                ...state,
                specialSubScreen: action.data
            }
            default:
                return state
    }
}