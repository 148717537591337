import styled from 'styled-components'

//固定的高宽
const headerHeight = 110 //头部高
const asideHeight = `200px` //侧边导航栏宽
const footerHeight = `42px` //底部高

//颜色值
const mainColor = '#5A8BFF' //主色调

export const Container = styled.div`
    height: 100%;
    width: 100%;
`

export const Header = styled.div`
    height: ${headerHeight}px;
    background: ${mainColor};
    color: white;
    .home-icon-container{
        width: ${asideHeight};
        height: 100%;
        float: left;
        position: relative;
        .home-icon{
            width: 60%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .header-right{
        width: calc(100% - ${asideHeight});
        height: 100%;
        float: right;
        .header-title{
            text-align: center;
            font-size: 26px;
            line-height: 26px;
            font-weight: bold;
            padding-top: 19px;
            padding-right: 200px;
        }
        .header-welcome{
            font-size: 16px;
            line-height: 16px;
            margin-right: 30px;
            text-align: right;
            span{
                margin: 0 10px;
                &:last-child{
                    cursor: pointer;
                    &:hover{
                        font-weight: bold;
                    }
                }
            }
        }
    }
`

export const Aside = styled.div `
    width: ${asideHeight};
    height: calc(100% - ${headerHeight}px);
    float: left;
    border-right: 1px solid rgb(232, 232, 232);
    overflow-y: scroll;
`

export const Content = styled.div `
    width: calc(100% - ${asideHeight});
    height: calc(100% - ${headerHeight - 40}px);
    float: right;
    transform: translateY(-40px);
    .content-main{
        height: calc(100% - ${footerHeight});
        .ant-tabs-tabpane{
            height: 100%;
            .content-inline{
            height: 100%;
        }
        }
    }
    .footer{
        font-size: 12px;
        padding: 14px 0;
        line-height: 14px;
        box-sizing: border-box;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        background: rgb(240, 242, 245);
    }
`
