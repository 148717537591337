import React, { useState, useEffect } from 'react'
import {Container} from './style'

import { Button, Input, Modal, Table, Popconfirm, Col, Form, message, Icon } from 'antd'
import DeTable from '../../../component/DeTable'
import { DatePicker } from 'antd';

import moment from 'moment'

import StoreSettingHttp from '../../../request/demo'
import CommonHttp from "../../../request/common-request";

const _request = new StoreSettingHttp()
const _commonrequest = new CommonHttp()
const { MonthPicker, RangePicker } = DatePicker;

function Demo(props) {
    const [modalType, setModalType] = useState('') //新增or编辑
    const [editVisible, setEditVisible] = useState(false) //编辑项
    const [confirmLoading, setConfirmLoading] = useState(false) //编辑模态框加载图标
    const [goodsName, setName] = useState('')
    const [washSpotName, setWashSpotName] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const tableColunm =
        [
            {
                title: '序号',
                key: 'number',
                render: (text, record, index) => (
                    <span>{index + 1}</span>
                )
            },
            {
                title: '洗消点',
                dataIndex: 'washSpotName',
                key: 'washSpotName',
            },
            {
                title: '物品名称',
                dataIndex: 'goodsName',
                key: 'goodsName',
            },
            {
                title: '操作流程',
                dataIndex: 'workflowName',
                key: 'workflowName',
            },
            {
                title: '所属猪场',
                key: 'pigFramName',
                dataIndex: 'pigFramName',
            },
            // {
            //     title: '操作人',
            //     key: 'operator',
            //     dataIndex: 'operator',
            // },
            {
                title: '操作时间',
                key: 'operationDate',
                dataIndex: 'operationDate',
				render: (text, record, index) => {
					return <span>{moment(record.operationDate).format('YYYY-MM-DD HH:mm:ss')}</span>;
				}
            },
        ]
    //表格结构
    const [tableTotal, setTableTotal] = useState(0)
    const [tableData, setTableData] = useState([]) //表格数据
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    }
    const { getFieldDecorator } = props.form
    useEffect(() => {
        getAllDecontamination()
    }, [])
    //获取物资列表
    const getAllDecontamination = (ButtonType, pageNo = 1, pageSize = 10) => {
        let params = {
            goodsName,
            washSpotName,
            startTime:startTime,
            endTime: endTime,
            pageNo,
            pageSize
        }
        if (ButtonType === 'reset') {
            params = {}
        }
        _request.findMaterialDecontamination(params).then(res => {
            setTableTotal(res.total || 0)
            setTableData(res.list)
        })
    }


    //搜索
    const clickSearch = () => {
        getAllDecontamination()
    }
    //重置
    const clickReset = () => {
		setName('')
		setWashSpotName('')
		setStartTime('')
		setEndTime('')
        getAllDecontamination('reset')
    }

    
    //日期控件搜索
    function range(start, end) {
	  const result = [];
	  for (let i = start; i < end; i++) {
	    result.push(i);
	  }
	  return result;
	}
	
	function disabledDate(current) {
	  // Can not select days before today and today
	  return current && current < moment().endOf('day');
	}

    function disabledDateTime() {
	  return {
	    disabledHours: () => range(0, 24).splice(4, 20),
	    disabledMinutes: () => range(30, 60),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
	function disabledRangeTime(_, type) {
	  if (type === 'start') {
	    return {
	      disabledHours: () => range(0, 60).splice(4, 20),
	      disabledMinutes: () => range(30, 60),
	      disabledSeconds: () => [55, 56],
	    };
	  }
	  return {
	    disabledHours: () => range(0, 60).splice(20, 4),
	    disabledMinutes: () => range(0, 31),
	    disabledSeconds: () => [55, 56],
	  };
	}
	
    function rangeTimeChange(date){
    	if(date){
    		if(date[0]!=undefined){
    			setStartTime(date[0].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setStartTime('');
    		}
    		if(date[1]!=undefined){
    			setEndTime(date[1].format('YYYY-MM-DD HH:mm:ss'));
    		}else{
    			setEndTime('');
    		}
    	}
    }

    return (
        <Container>
            <div className="top">
                <Input placeholder="请输入洗消点" style={{ width: 200, marginRight: 10 }} value={washSpotName} onPressEnter={clickSearch} onChange={(e) => setWashSpotName(e.target.value)}></Input>
                <Input placeholder="请输入物品名称" style={{ width: 200, marginRight: 10 }} value={goodsName} onPressEnter={clickSearch} onChange={(e) => setName(e.target.value)}></Input>
                <RangePicker
                	ranges={{
				        Today: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				        'This Month': [moment().startOf('month'), moment().endOf('month')],
				      }}
	                value={startTime===''||endTime===''?null:[moment(startTime, "YYYY-MM-DD HH:mm:ss"), moment(endTime, "YYYY-MM-DD HH:mm:ss")]}
				    /*disabledTime={disabledRangeTime}*/
				    onChange={rangeTimeChange}
				    showTime={{
				    	hideDisabledOptions: true,
				        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
				    }}
				    format="YYYY-MM-DD HH:mm:ss"
				/>
	                
               	&nbsp;&nbsp;
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickSearch}>查找</Button>
                <Button type="primary" style={{ marginRight: 10 }} onClick={clickReset}>重置</Button>
            </div>
            <div className="bottom">
            	<DeTable columns={tableColunm} total={tableTotal} onPaginationChange={(pageNo, pageSize) => getAllDecontamination('', pageNo, pageSize)}  dataSource={tableData} />
            </div>
        </Container>

    )
}

export default Form.create()(Demo)