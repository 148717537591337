import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------员工管理
    //获取人员列表
    getPeopleList(params) {
        return request({
            url: '/client/people/list',
            method: 'post',
            params
        })
    }
	//获取当前登录用户下的猪场集合
	getPigfarmList(params){
		return request({
		    url: '/client/area/getChildrenRegionInfo',
		    method: 'post',
		    params
		})
	}
	
    //新增员工
    addPeopleInfo(params) {
        return request({
            url: '/client/people/add',
            method: 'post',
            params
        })
    }
    //编辑员工
    editPeopleInfo(params) {
        return request({
            url: '/client/people/update',
            method: 'post',
            params
        })
    }
    //删除员工
    deletePeopleInfo(params) {
        return request({
            url: '/client/people/delete',
            method: 'post',
            params
        })
    }
}

export default StoreSettingHttp