import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------员工管理
    //获取人员列表
    getWashList(params) {
        return request({
            url: '/client/wash/list',
            method: 'post',
            params
        })
    }
}

export default StoreSettingHttp