import React, {useState, useEffect} from 'react'

import { Container } from './style'

import {Table} from 'antd'

function DeTable(props) {
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
    }, [])
    const { total, onPaginationChange } = props

    const handleChange = (no, size) => {
        setCurrent(no)
        setPageSize(size)
        onPaginationChange(no, size)
    }
    const handleSizeChange = (no, size) => {
        setCurrent(no)
        setPageSize(size)
        onPaginationChange(no, size)
    }
    return (
        <Container>
            <Table pagination={{ size: 'small', showTotal: (total, range) => `显示${range[0]}到${range[1]}，共${total}条记录`, current, pageSize, showSizeChanger: true, total, onChange: handleChange, onShowSizeChange: handleSizeChange } } {...props} />
        </Container>
    )
}


export default DeTable