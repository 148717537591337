import styled from 'styled-components'

import bgImage from '../../img/bg.jpg'

export const Container = styled.div `
    height: 100%;
    width: 100%;
    background: url(${bgImage}) no-repeat;
    background-size: cover;
    background-color: #5A8BFF;
    position: relative;
`

export const LoginBox = styled.div`
    position: absolute;
    width: 36%;
    height: 60%;
    min-width: 500px;
    min-height: 460px;
    background: white;
    box-shadow: 0px 0px 3px white;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    .login-form{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            width: 100%;
            text-align: center;
            font-size: 26px;
            font-weight: bold;
            color: black;
            padding-top: 10px;
        }
        .back-to-login{
            position: absolute;
            top: 30px;
            left: 5%;
        }
    }
`

export const Footer = styled.div`
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    color: white;
`