import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------品类管理
    //获取品类列表
    getAllType(params) {
        return request({
            url: '/category/categoryList',
            method: 'post',
            params
        })
    }
    //新增品类
    addType(params) {
        return request({
            url: '/category/addGoodsCategory',
            method: 'post',
            params
        })
    }
    //编辑品类
    editType(params) {
        return request({
            url: '/category/updateCategory',
            method: 'post',
            params
        })
    }
    //删除品类
    deleteType(params) {
        return request({
            url: '/category/deleteOneCategory',
            method: 'post',
            params
        })
    }
    //导入
    addMultiType(data) {
        return request({
            url: '/category/addMultiCategory',
            method: 'post',
            data
        })
    }

    //--------------------------------------------------------物资管理
    //所有物资
    findMaterialRegister(data) {
        return request({
            url: 'client/register/list',
            method: 'post',
            data
        })
    }
    //新增物资
    addMaterialRegister(data) {
        return request({
            url: 'client/register/add',
            method: 'post',
            data
        })
    }
    //修改物资
    updateMaterialRegister(data) {
        return request({
            url: 'client/register/update',
            method: 'post',
            data
        })
    }
    //删除物资
    deleteMaterialRegister(data) {
        return request({
            url: 'client/register/delete',
            method: 'post',
            data
        })
    }
    //物资 洗消记录
    findMaterialDecontamination(data) {
        return request({
            url: 'client/decontamination/list',
            method: 'post',
            data
        })
    }
    //物品库存
    findMaterialStock(data) {
        return request({
            url: 'client/stock/list',
            method: 'post',
            data
        })
    }
    //查询物品出入库
    findMaterialOutAndWarehousing(data) {
        return request({
            url: 'client/outAndWarehousing/list',
            method: 'post',
            data
        })
    }
    //新增物品出入库
    addMaterialOutAndWarehousing(data) {
        return request({
            url: 'client/outAndWarehousing/add',
            method: 'post',
            data
        })
    }
}

export default StoreSettingHttp