import request from '../https'

class CarRequest{
//组列表
    getWashGroupList(params){
        return request({
            url: 'client/group/list',
            method: 'post',
            params
        })
    }
    
        //新增保存洗消点
    addWashGroup(params) {
        return request({
            url: 'client/group/add',
            method: 'POST',
            params
        });
    }
    //修改保存洗消点
    updateWashGroup(params) {
        return request({
            url: 'client/group/update',
            method: 'PUT',
            params
        });
    }
    //删除洗消点
    deleteWashGroup(params) {
        return request({
            url: 'client/group/delete/' + params.id,
            method: 'DELETE'
        });
    }
     
   
   
   
    
}

export default CarRequest