import request from '../https'

class StoreSettingHttp {
    //--------------------------------------------------------临时访客登记
    //获取临时访客登记列表
    getVisitorList(params) {
        return request({
            url: '/client/visitor/list',
            method: 'post',
            params
        })
    }
	//获取当前登录用户下的猪场集合
	getPigfarmList(params){
		return request({
		    url: '/client/area/getChildrenRegionInfo',
		    method: 'post',
		    params
		})
	}
	
    //新增临时访客登记
    addVisitor(params) {
        return request({
            url: '/client/visitor/add',
            method: 'post',
            params
        })
    }
    //编辑临时访客登记
    editVisitor(params) {
        return request({
            url: '/client/visitor/update',
            method: 'post',
            params
        })
    }
    //删除临时访客登记
    deleteVisitor(params) {
        return request({
            url: '/client/visitor/delete',
            method: 'post',
            params
        })
    }
}

export default StoreSettingHttp